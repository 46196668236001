<template>
    <div class="customer-dashboard bg-slate-50 min-h-screen">
        <div class="wrapper-customer-dashboard lg:flex min-h-screen">
            <div class="aside">
                <AsideCustomerDashboard></AsideCustomerDashboard>
            </div>

            <div class="content container mx-auto py-20 px-12 2xl:px-4">
                <!-- <CreditCard/> -->
                <h2 class="font-bold text-3xl text-center text-neutral-600">Confirmação do Pedido</h2>

                <hr class="block my-6">

                <div class="flex justify-center items-center gap-5 mb-5">
                    <div class="grow max-w-[250px] bg-white border shadow-md rounded-lg px-2 py-6" v-for="item in cartItems" :key="item.vehicle_id"> 
                        <div class="text-center text-blue-800">
                            <i class="fa-solid fa-motorcycle text-4xl" v-if="item.vehicle_type === 'motorcycle'"></i>
                            <i class="fa-solid fa-car text-4xl" v-else-if="item.vehicle_type === 'tour'"></i>
                            <i class="fa-solid fa-truck text-4xl" v-else-if="item.vehicle_type === 'truck'"></i>
                        </div>

                        <p class="text-blue-800 text-2xl text-center">{{ item.vehicle_model }}</p>

                        <div class="text-center">
                            <p class="m-0 font-bold text-2xl">{{ item.plan_name }}</p>
                            <p class="mb-1">{{ getTotalPrice(parseFloat(item.plan_price)) }}</p>
                            <p class="text-xs text-neutral-500" v-if="item.active_equipment_rate">+Equipamento: {{ getTotalPrice(parseFloat(item.equipment_rate)) }}</p>
                            <p class="text-xs text-neutral-500" v-if="item.active_activation_rate">+Taxa Ativação: {{ getTotalPrice(parseFloat(item.activation_rate)) }}</p>
                        </div>
                    </div>
                </div>

                <p class="total-order text-center text-lg">Total da contratação: <b>{{ getTotalPrice(totalOrder) }}</b></p>

                <!-- Método de Pagamento -->
                <div class="my-20">
                    <p class="text-center font-bold text-neutral-600 text-2xl">Selecione um método de pagamento</p>

                    <div class="payment_methods lg:flex justify-center gap-4 mt-4">
                        <!-- Pix -->
                        <div class="lg:max-w-[200px] flex flex-col mb-3 lg:mb-0 bg-white border shadow-md p-1 py-6 text-center rounded-lg grow">
                            <img class="block mb-4 pix" src="@/assets/pix-106.webp" alt="">
                            <div>
                                <p class="block w-full text-[0.65rem] font-bold text-neutral-400 mb-3">Pagar com Pix</p>
                                <RadioButton @click="setPayment('pix')" v-model="paymentMethod" variant="outlined" class="" inputId="pix" name="payment_method" value="pix" />
                            </div>
                            <button @click="paymentMethod = 'pix', setPayment('pix')" class="px-2 py-1 rounded bg-blue-600 text-white text-sm mt-5 hover:bg-blue-700 duration-150">Selecionar Pix</button>
                        </div>

                        <!-- Cartão de Crédito -->
                        <div class="lg:max-w-[200px] flex flex-col mb-3 lg:mb-0 bg-white border shadow-md p-1 py-6 text-center rounded-lg grow">
                            <img class="block mb-4 credit_card" src="@/assets/Credit-Card.webp" alt="">
                            <div>
                                <p class="block w-full text-[0.65rem] font-bold text-neutral-400 mb-3">Pagar com Cartão de Crédito</p>
                                <RadioButton @click="setPayment('credit_card')" v-model="paymentMethod" variant="outlined" class="" inputId="credit_card" name="payment_method" value="credit_card" />
                            </div>
                            <button @click="paymentMethod = 'credit_card', setPayment('credit_card')" class="px-2 py-1 rounded bg-blue-600 text-white text-sm mt-5 hover:bg-blue-700 duration-150">Selecionar Cartão</button>
                        </div>

                        <!-- Boleto -->
                        <div class="lg:max-w-[200px] flex flex-col bg-white border shadow-md p-1 py-6 text-center rounded-lg grow">
                            <img class="block mb-4 ticket" src="@/assets/boleto.webp" alt="">
                            <div>
                                <p class="block w-full text-[0.65rem] font-bold text-neutral-400 mb-3">Pagar com Boleto</p>
                                <RadioButton @click="setPayment('ticket')" v-model="paymentMethod" variant="outlined" class="" inputId="ticket" name="payment_method" value="ticket" />
                            </div>
                            <button @click="paymentMethod = 'ticket', setPayment('ticket')" class="px-2 py-1 rounded bg-blue-600 text-white text-sm mt-5 hover:bg-blue-700 duration-150">Selecionar Boleto</button>
                        </div>
                    </div>

                    <span class="text-sm text-center block mt-5 text-red-400" v-if="!paymentMethod">Para continuar é preciso selecionar um Método de Pagamento</span>
                </div>
                <!-- End Método de Pagamento -->

                <div class="text-center mb-5">
                    <p @click="term_visible = true" class="text-sm bg-neutral-700 inline-block text-white p-2 rounded-lg mb-2 cursor-pointer">Abrir Termo de Contratação</p>
                    <span class="text-sm block text-red-400" v-if="!acceptTerm">Para continuar é preciso aceitar o Termo de Contratação</span>
                    <span class="block text-sm font-bold text-green-500" v-else>Termo Aceito <i class="pi pi-check"></i></span>
                </div>

                <Dialog v-model:visible="term_visible" maximizable modal header="Termo de Contratação" class="relative" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                    <div class="mb-5">
                        <p class="m-0" v-html="hiringTerm"></p>
                    </div>

                    <div class="absolute w-full left-0 bottom-0 px-5 py-4 bg-white">
                        <hr class="mb-4">
                        <Checkbox inputId="acceptTerm" v-model="acceptTerm" @click="closeTerm" :binary="true" />
                        <label for="acceptTerm" class="ml-2 font-bold">Li e Aceito os Termos e Condições</label>
                    </div>
                </Dialog>

                <div :class="buttons ? 'block' : 'hidden'" class="text-center flex justify-center items-center gap-2 py-5">
                    <RouterLink to="/area-cliente" id="button-next" class="bg-red-600 hover:bg-red-700 duration-200 text-white p-2 px-5 rounded-lg font-bold block">
                        <i class="fa-solid fa-arrow-left"></i> Voltar
                    </RouterLink>

                    <button @click="createOrder" v-if="paymentMethod && acceptTerm" id="button-next" class="bg-blue-800 hover:bg-blue-900 duration-200 text-white p-2 px-5 rounded-lg font-bold block">
                        Continuar <i class="fa-solid fa-arrow-right"></i>
                    </button>
                </div>

                <div :class="buttons ? 'hidden' : 'block'" class="loader">
                    <ProgressBar mode="indeterminate" class="rounded-none h-6"></ProgressBar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AsideCustomerDashboard from '@/components/Customer/AsideCustomerDashboard.vue'
import Card from 'primevue/card'
import RadioButton from 'primevue/radiobutton'
import ProgressBar from 'primevue/progressbar'
import Dialog from 'primevue/dialog'
import Checkbox from 'primevue/checkbox'

import cartService from '@/services/cartService'
import paymentService from '@/services/paymentService'
import orderService from '@/services/orderService'
import authService from '@/services/authService'
import contractsService from '@/services/contractsService'

import CreditCard from '@/components/Payments/CreditCard.vue'

export default {
    name: 'Customer Order',
    data() {
        return {
            cartItems: null,
            totalOrder: 0,
            paymentMethod: null,
            acceptTerm: false,
            buttons: true,
            term_visible: true,
            hiringTerm: '',
        }
    },
    methods: {
        closeTerm() {
            setTimeout(() => {
                this.term_visible = false
            }, 100)
        },
        getTotalPrice(price) {
            return price.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
            })
        },
        setPayment(method) {
            paymentService.setPayment({
                payment_method: method,
                total: this.totalOrder
            })
        },
        async createOrder() {
            this.buttons = false
            const order = await orderService.requestNewOrder()
        },
    },
    beforeCreate() {
        const auth = authService
        auth.isAuthenticated()
    },
    mounted() {
        this.cartItems = cartService.getItemsCart()
        this.totalOrder = cartService.getTotalCart()
        this.paymentMethod = paymentService.getPaymentMethod()

        if (this.cartItems === null) {
            this.$router.push('/area-cliente')
            return false
        }

        if (cartService.validateCartTimestamp()) {
            this.$router.push('/area-cliente')
            cartService.emptyCart()
            cartService.deleteCartTimestamp()
            localStorage.removeItem('payment_method')
            localStorage.removeItem('order_hash')
            return false
        }

        contractsService.getHiringTerm().then((response) => {
            this.hiringTerm = response.data.contract_model_html
        })
    },
    components: {
        AsideCustomerDashboard,
        Card,
        RadioButton,
        ProgressBar,
        CreditCard,
        Dialog,
        Checkbox
    }
}
</script>

<style lang="scss">
.payment_methods {
    & > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .p-radiobutton-box {
        border: 1px solid rgba(0,0,0,0.2);
    }
    img {
        width: 100%;
        height: 45px;
        object-fit: contain;
        object-position: center center;
        display: block;
        &.ticket {
            margin-bottom: 15px;
            height: 40px;
        }
    }
}
</style>