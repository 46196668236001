<template>
    <div>
        <div class="card">
            <Toolbar class="mb-4 bg-white shadow">
                <template #start>
                    <Button label="Novo Cliente" icon="pi pi-plus" severity="success" class="mr-2 bg-blue-600 text-white p-3" @click="openNew"/>

                    <Button label="Desativar" icon="pi pi-ban" severity="danger" class="bg-red-500 text-white p-3" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" />
                </template>

                <template #end>
                    <!-- <FileUpload
                        mode="basic"
                        name="importCustomers"
                        url="http://online-tecnologia.local/api/import-customers"
                        accept="application/json"
                        :maxFileSize="1000000000"
                        @before-upload="beforeUpload"
                        @upload="onImportCustomers"
                        @error="errorUpload"
                        :auto="true"
                        chooseLabel="Importar Clientes"
                    /> -->
                    <!-- <Button class="bg-indigo-700 p-3 text-white" label="Exportar" icon="pi pi-upload" severity="help" @click="exportCSV($event)"  /> -->
                </template>
            </Toolbar>

            <div v-if="import_customers" class="text-center my-3">
                <p class="font-bold">Importando Clientes. Aguarde...</p>
                <ProgressBar mode="indeterminate" style="height: 6px"/>
            </div>

            <DataTable
                ref="dt"
                :value="customers"
                class="text-sm shadow"
                v-model:selection="selectedProducts"
                dataKey="customer_id"
                :paginator="true"
                :rows="25"
                :rowsPerPageOptions="[5,10,25,50,100]"
                :filters="filters"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} clientes">

                <template #header>
                    <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
						<IconField iconPosition="left">
                            <InputIcon>
                                <i class="pi pi-search me-3" />
                            </InputIcon>

                            <InputText class="bg-white p-3 border" v-model="filters['global'].value" placeholder="Buscar Clientes..." />
                        </IconField>
					</div>
                </template>

                <Column selectionMode="multiple" :exportable="false"></Column>

                <Column field="customer_id" header="ID" sortable></Column>

                <Column field="name" header="Nome" sortable></Column>

                <Column field="customer_type" header="Tipo" sortable class="uppercase">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.customer_type === 'pf'" class="bg-blue-500 text-white px-3 py-1 rounded-md">{{ slotProps.data.customer_type }}</span>
                        <span v-else class="bg-orange-500 text-white px-3 py-1 rounded-md">{{ slotProps.data.customer_type }}</span>
                    </template>
                </Column>

                <Column field="document" header="Documento" sortable></Column>

                <Column field="created_at" header="Data de Cadastro" sortable>
                    <template #body="slotProps">
                        {{ formatDate(slotProps.data.created_at) }}
                    </template>
                </Column>

                <!-- Active Customer -->
                <Column class="text-center" field="active" header="Ativo">
                    <template #body="slotProps">
                        <input class="checkActive" type="checkbox" :value="slotProps.data.active" :checked="slotProps.data.active" @click="updateActiveCustomer($event, slotProps.data.customer_id)">
                    </template>
                </Column>
                <!-- End Active Customer -->

                <Column :exportable="false" header="Ações" class="flex">
                    <template #body="slotProps">
                        <Button icon="pi pi-file-pdf" title="Novo Contrato" outlined rounded class="bg-sky-400 hover:bg-indigo-900 text-white mr-2 w-8 h-8" @click="openNewContract(slotProps.data.customer_id)" />
                        <Button icon="pi pi-car" title="Veículos" outlined rounded class="bg-indigo-700 hover:bg-indigo-900 text-white mr-2 w-8 h-8" @click="editCustomerVehicles(slotProps.data)" />
                        <Button icon="pi pi-info-circle" title="Ver Informações" outlined rounded class="bg-indigo-700 hover:bg-indigo-900 text-white mr-2 w-8 h-8" @click="editCustomer(slotProps.data)" />
                        <!-- <Button icon="pi pi-pencil" title="Editar Cliente" outlined rounded class="bg-cyan-500 hover:bg-cyan-700 text-white mr-2 w-8 h-8" @click="editProduct(slotProps.data)" /> -->
                        <!-- <Button icon="pi pi-trash" title="Excluir Cliente" outlined rounded severity="danger" class="bg-red-500 hover:bg-red-700 text-white w-8 h-8" @click="confirmDeleteCustomer(slotProps.data)" /> -->
                    </template>
                </Column>
            </DataTable>
        </div>

        <!-- New Customer -->
        <Dialog v-model:visible="newCustomerDialog" :style="{width: '850px'}" :modal="true" class="p-fluid">
            <p class="font-bold mb-2 text-xl">Dados do Cliente</p>

            <div>
                <label class="font-bold mb-1 block text-sm" for="name">Tipo de Pessoa</label>
                <SelectButton
                    v-model="customer.customer_type"
                    :options="customer_types"
                    optionLabel="label"
                    optionValue="value"
                    class="customer_type border rounded-md mt-3 mb-4 max-w-[380px] text-xs"
                    aria-labelledby="basic"
                />
            </div>        

            <div class="field mb-2">
                <label class="font-bold mb-1 block text-sm" for="name">Nome/Razão Social*</label>
                <InputText id="name" v-model.trim="customer.name" required="true" autofocus :class="{'p-invalid': submitted && !customer.name}" class="p-2 border" />
                <small class="p-error" v-if="submitted && !customer.name">Nome é obrigatório.</small>
            </div>

            <div class="flex gap-3 mb-2">
                <div class="field grow">
                    <label class="font-bold mb-1 block text-sm" for="document">Documento*</label>
                    <InputText id="document" v-model="customer.document" required="true" maxlength="14" :class="{'p-invalid': submitted && !customer.document}" class="p-2 border" />
                    <small class="p-error" v-if="submitted && !customer.document">Documento é obrigatório.</small>
                </div>

                <div class="field grow">
                    <label class="font-bold mb-1 block text-sm" for="birthday">Data de Nascimento</label>
                    <Calendar dateFormat="dd/mm/yy" :locale="localeSettings" id="birthday" v-model="customer.birthday" class="border rounded-md" />
                </div>
            </div>

            <hr class="my-8">

            <!-- Dados Residenciais -->
            <div>
                <p class="font-bold mb-2 text-xl">Dados de Endereço</p>

                <SelectButton
                    v-model="customer.address.type_address"
                    :options="address_types"
                    optionLabel="label"
                    optionValue="value"
                    class="type_address border rounded-md mt-3 mb-4 max-w-[220px] text-xs"
                    aria-labelledby="basic"
                />

                <div class="flex gap-3">
                    <div class="field mb-2">
                        <label class="font-bold mb-1 block text-sm" for="zipcode">CEP</label>
                        <InputText @keyup="getCep" id="zipcode" maxlength="8" v-model="customer.address.zipcode" class="p-2 border" />
                    </div>

                    <div class="field mb-2 grow">
                        <label class="font-bold mb-1 block text-sm" for="street">Rua</label>
                        <InputText id="street" v-model="customer.address.street" class="p-2 border" />
                    </div>
                </div>

                <ProgressBar v-if="search_zipcode" mode="indeterminate" class="h-1 rounded-none mb-2"></ProgressBar>

                <div class="flex gap-3">
                    <div class="field mb-2 w-[15%]">
                        <label class="font-bold mb-1 block text-sm" for="number">Número</label>
                        <InputText id="number" v-model="customer.address.number" class="p-2 border" />
                    </div>

                    <div class="field mb-2 grow">
                        <label class="font-bold mb-1 block text-sm" for="neighborhood">Bairro</label>
                        <InputText id="neighborhood" v-model="customer.address.neighborhood" class="p-2 border" />
                    </div>
                </div>

                <div class="flex gap-3">
                    <div class="field mb-2 grow">
                        <label class="font-bold mb-1 block text-sm" for="city">Cidade</label>
                        <InputText id="city" v-model="customer.address.city" class="p-2 py-3 border" />
                    </div>

                    <div class="field mb-2 grow">
                        <label class="font-bold mb-1 block text-sm" for="state">Estado</label>
                        <Dropdown
                            id="state"
                            class="border"
                            v-model="customer.address.state"
                            :options="states"
                            optionLabel="nome"
                            optionValue="sigla"
                            placeholder="Selecione o Estado"
                        />
                    </div>
                </div>

                <div class="flex gap-3">
                    <div class="field mb-2 grow">
                        <label class="font-bold mb-1 block text-sm" for="complement">Complemento</label>
                        <InputText id="complement" v-model="customer.address.complement" class="p-2 border" />
                    </div>

                    <div class="field mb-2 grow">
                        <label class="font-bold mb-1 block text-sm" for="reference">Referência</label>
                        <InputText id="reference" v-model="customer.address.reference" class="p-2 border" />
                    </div>
                </div>
            </div>

            <hr class="my-8">

            <!-- Dados de Contato -->
            <div>
                <p class="font-bold mb-2 text-xl">Dados de Contato</p>

                <div class="list-customer-contacts">
                    <div class="flex gap-3 row-customer-contacts" :class="index !== 0 ? 'mt-2' : ''" v-for="(f, index) in this.contact_fields" :key="f">
                        <div class="field w-[250px]">
                            <label class="font-bold mb-1 block text-sm" for="street">Tipo de Contato</label>
                            <Dropdown
                                v-if="index !== 0"
                                class="p-0 border"
                                v-model="customer.contacts[index].contact_type"
                                :options="contacts_type"
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Tipo de Contato"/>

                            <Dropdown
                                v-else
                                modelValue="email"
                                class="p-0 border"
                                v-model="customer.contacts[index].contact_type"
                                :options="contacts_type"
                                optionLabel="label"
                                optionValue="value"
                                :disabled="true"
                                placeholder="Tipo de Contato"/>
                        </div>

                        <div class="field grow">
                            <label class="font-bold mb-1 block text-sm" for="street">Contato</label>
                            <InputText id="street" v-model="customer.contacts[index].contact_detail" class="p-2 py-3 border" />
                        </div>

                        <div class="self-center" @click="removeContact(index)" v-if="index !== 0">
                            <i class="pi pi-trash text-lg mt-6 cursor-pointer"></i>
                        </div>
                    </div>
                </div>
                
                <button @click="addNewContact" type="button" class="px-2 bg-blue-600 text-white mt-4 py-1 text-sm rounded">Novo Contato <i class="text-xs pi pi-plus"></i></button>
                    
            </div>

            <hr class="mt-4">

            <template #footer>
                <Button class="p-2 text-white bg-red-500 me-3" label="Fechar" icon="pi pi-times" text @click="hideDialog"/>
                <Button class="p-2 text-white bg-green-500" label="Salvar Cliente" icon="pi pi-check" text @click="saveCustomer" />
            </template>
        </Dialog>

        <!-- Delete Single Customer -->
        <!-- <Dialog v-model:visible="deleteCustomerDialog" :style="{width: '450px'}" header="Confirmação" :modal="true">
            <div class="confirmation-content flex items-center">
                <i class="pi pi-exclamation-triangle mr-3 text-red-400" style="font-size: 2rem" />
                <span v-if="deleteCustomer">Tem certeza que deseja excluir <b>{{deleteCustomer.name}}</b>?</span>
            </div>
            <template #footer>
                <Button class="p-2 rounded-md bg-red-500 me-2 text-white" label="Não" icon="pi pi-times" text @click="deleteCustomerDialog = false"/>
                <Button class="p-2 rounded-md bg-green-500 text-white" label="Sim" icon="pi pi-check" text @click="deleteProduct" />
            </template>
        </Dialog> -->

        <!-- Delete Multiples Customers -->
        <Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="customer">Are you sure you want to delete the selected products?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" text @click="deleteProductsDialog = false"/>
                <Button label="Yes" icon="pi pi-check" text @click="deleteSelectedProducts" />
            </template>
        </Dialog>

        <!-- Novo Contrato -->
        <Dialog v-model:visible="newContractDialog" :style="{width: '850px'}" :modal="true" class="p-fluid">
            <p class="font-bold mb-2 text-xl">Novo Contrato</p>

            <hr class="my-5">
            
            <label class="font-bold block mb-2" for="">Selecione o modelo de Contrato</label>
            <Dropdown
                class="p-0 border"
                v-model="new_contract_data.contract_type"
                :options="contracts"
                optionLabel="label"
                optionValue="value"
                placeholder="Tipo de Contrato"
                @change="selectContractModel"
            />

            <template #footer>
                <Button class="p-2 text-white bg-red-500 me-3" label="Fechar" icon="pi pi-times" @click="newContractDialog = false"/>
                <Button class="p-2 text-white bg-green-500" label="Salvar Contrato" icon="pi pi-check" :disabled="disabled_button" @click="saveNewContract" />
            </template>
        </Dialog>

        <!-- Cliente Info -->
        <Dialog v-model:visible="customerInfoDialog" :style="{width: '850px'}" :modal="true" class="p-fluid">
            <h2 class="font-bold text-xl mb-2">Dados Pessoais</h2>
            <p><b>Nome:</b> {{ customerData.name }}</p>  
            <p><b>Data de Nascimento:</b> {{ formatBirthday(customerData.birthday) }}</p>  
            <p><b>Documento:</b> {{ customerData.document }}</p>  
            <p><b>Nome:</b> {{ customerData.customer_type == 'pf' ? 'Pessoa Física' : 'Pessoa Jurídica' }}</p>

            <hr class="my-5">

            <h2 class="font-bold text-xl mb-2">Contatos</h2>
            <div class="border p-2 rounded mb-1" v-for="contact in customerData.contacts" :key="contact.contact_id">
                <b>{{ contact.contact_type.charAt(0).toUpperCase() + contact.contact_type.slice(1) }}:</b> - {{ contact.contact_detail }}
            </div>

            <hr class="my-5">

            <h2 class="font-bold text-xl mb-2">Endereços</h2>
            <div class="border p-2 rounded mb-1" v-for="address in customerData.address" :key="address.address_id">
                <p class="m-0"><b>Rua:</b> {{ address.street }}</p>
                <p class="m-0"><b>Número:</b> {{ address.number }}</p>
                <p class="m-0"><b>Bairro:</b> {{ address.neighborhood }}</p>
                <p class="m-0"><b>CEP:</b> {{ address.zipcode }}</p>
                <p class="m-0"><b>Cidade:</b> {{ address.city }}</p>
                <p class="m-0"><b>UF:</b> {{ address.state }}</p>
                <p class="m-0"><b>Referência:</b> {{ address.reference }}</p>
                <p class="m-0"><b>Complemento</b> {{ address.complement }}</p>
            </div>
        </Dialog>

        <!-- Cliente Vehicles -->
        <Dialog v-model:visible="customerVehiclesDialog" :style="{width: '850px'}" class="p-fluid" header="Veículos do Cliente" :modal="true">
            <p v-if="customerVehicles.length < 1" class="text-red-400 font-bold">Sem veículos cadastrados no momento!</p>
            <div v-else>
                <div class="border p-2 rounded mb-1 flex gap-5" v-for="vehicle in customerVehicles" :key="vehicle.vehicle_id">
                    <div class="text-2xl text-blue-800 bg-slate-200 flex justify-center items-center w-[50px]">
                        <i class="fa-solid fa-motorcycle" v-if="vehicle.vehicle_type === 'Motocicleta'"></i>
                        <i class="fa-solid fa-car" v-else-if="vehicle.vehicle_type === 'Passeio'"></i>
                        <i class="fa-solid fa-truck" v-else-if="vehicle.vehicle_type === 'Caminhão'"></i>
                        <i class="fa-solid fa-truck-pickup" v-else-if="vehicle.vehicle_type === 'Caminhonete'"></i>
                        <i class="fa-solid fa-circle-question" v-else></i>
                    </div>

                    <div>
                        <p class="m-0"><b>Tipo:</b> {{ vehicle.vehicle_type }}</p>
                        <p class="m-0"><b>Modelo:</b> {{ vehicle.model }}</p>
                        <p class="m-0"><b>Placa:</b> {{ vehicle.plate }}</p>
                        <p class="m-0"><b>RENAVAM:</b> {{ vehicle.renavam }}</p>
                        <p class="m-0"><b>Ano:</b> {{ vehicle.year }}</p>
                        <p class="m-0"><b>Marca:</b> {{ vehicle.brand }}</p>
                        <p class="m-0"><b>Cor:</b> {{ vehicle.color }}</p>
                    </div>
                </div>
            </div>   
        </Dialog>
    </div>
    <Toast></Toast>
</template>

<script>
import DataTable from 'primevue/datatable'
import Button from 'primevue/button'
import Column from 'primevue/column'
import FileUpload from 'primevue/fileupload'
import Toolbar from 'primevue/toolbar'
import Dialog from 'primevue/dialog'
import Tag from 'primevue/tag'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import RadioButton from 'primevue/radiobutton'
import ProgressBar from 'primevue/progressbar'
import SelectButton from 'primevue/selectbutton'
import Checkbox from 'primevue/checkbox'
import InputSwitch from 'primevue/inputswitch'

import { FilterMatchMode } from 'primevue/api'
import customersService from '@/services/customersService'
import dateService from '@/services/dateService'
import requestService from '@/services/requestService'
import locationService from  '@/services/locationService'
import contractsModelsService from '@/services/contractsModelsService'
import contractsService from '@/services/contractsService'
import vehiclesService from '@/services/vehiclesService'

export default {
    data() {
        return {
            customers: [],
            customer: {
                customer_type: 'pf',
                address: {
                    type_address: 'home'
                },
                contacts: [{contact_type: 'email', contact_detail: ''}]
            },
            customerData: {},
            customerVehicles: {},
            contact_fields: 1,
            states: [],
            search_zipcode: false,
            newCustomerDialog: false,
            customerInfoDialog: false,
            customerVehiclesDialog: false,
            deleteCustomer: null,
            activeCustomer: null,
            toggleActiveCustomer: [],
            deleteCustomerDialog: false,
            deleteProductsDialog: false,
            import_customers: false,
            file_import: null,
            selectedProducts: null,
            filters: {},
            submitted: false,
            disabled_button: true,
            customer_types: [
                {label: 'Pessoa Física', value: 'pf'},
                {label: 'Pessoa Jurídica', value: 'pj'},
            ],
            address_types: [
                {label: 'Casa', value: 'home'},
                {label: 'Trabalho', value: 'work'},
            ],
            contacts_type: [
                {label: 'Telefone', value: 'phone_number'},
                {label: 'Telefone 2', value: 'phone_number_2'},
                {label: 'E-mail', value: 'email'},
                {label: 'WhatsApp', value: 'whatsapp'},
                {label: 'Fax', value: 'fax'},
            ],
            newContractDialog: false,
            contracts: [],
            new_contract_data: {
                contract_type: null,
                customer: null,
            },
        }
    },
    created() {
        this.initFilters();
    },
    methods: {
        formatCurrency(value) {
            if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
        },
        formatDate(date) {
            return dateService.formatBR(date)
        },
        formatBirthday(birthday) {
            var dataObjeto = new Date(birthday);
            var dia = dataObjeto.getDate();
            var mes = ("0" + (dataObjeto.getMonth() + 1)).slice(-2);
            var ano = dataObjeto.getFullYear();
            var dataFormatada = `${dia}/${mes}/${ano}`;

            return dataFormatada;
        },
        openNew() {
            this.product = {};
            this.submitted = false;
            this.newCustomerDialog = true;
        },
        hideDialog() {
            this.newCustomerDialog = false;
            this.submitted = false;
            customersService.allCustomers().then((response) => {
                localStorage.removeItem('customers')
                this.customers = response.data
                localStorage.setItem('customers', JSON.stringify(response.data))
            })
        },
        saveCustomer() {
            this.submitted = true;

			if (this.customer.name && this.customer.name.trim()) {
                if (this.customer.contacts[0].contact === '') {
                    this.$swal.fire({
                        title: 'Campo de E-mail Vazio!',
                        icon: 'warning'
                    })
                    return
                }

                const newCustomer = requestService.post('customer/new', this.customer).then((response) => {
                    console.log(response)

                    if (!response.data) {
                        this.$swal.fire('Erro ao salvar cliente', 'Erro no sistema', 'error')
                        return
                    }

                    switch (response.data.status) {
                        case 400:
                            this.$swal.fire('Não foi possível criar o Cliente', 'Verifique os dados inseridos.', 'warning')
                            break;
                        case 401:
                            this.$swal.fire('Dados de endereço incomplentos', 'Verifique os dados inseridos.', 'warning')
                            break;
                        case 200:
                            this.$swal.fire('Cliente cadastrado com sucesso!', 'Os dados do cliente foram salvos com sucesso.', 'success')
                            this.contact_fields = 1
                            this.submitted = false
                            this.customer = {
                                customer_type: 'pf',
                                address: {
                                    type_address: 'home'
                                },
                                contacts: [{type: 'email', contact: ''}]
                            }
                            customersService.removeLocalCustomers()
                            break;
                    }
                    
                })
            }
        },
        updateActiveCustomer(event, customer) {
            customersService.setCustomerStatus(customer, event.target.checked).then((response) => {
                if (!response.data.status || response.data.status !== 200) {
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao atualizar status do cliente', life: 3000 });
                    return false
                }

                this.$toast.add({ severity: 'success', summary: 'Status Atualizado', detail: 'O status do cliente foi atualizado com sucesso!', life: 3000 });
            })
        },
        editProduct(product) {
            this.product = {...product};
            this.newCustomerDialog = true;
        },
        confirmDeleteCustomer(customer) {
            this.deleteCustomer = customer;
            this.deleteCustomerDialog = true;
        },
        deleteProduct() {
            this.products = this.products.filter(val => val.id !== this.product.id);
            this.deleteCustomerDialog = false;
            this.product = {};
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.products.length; i++) {
                if (this.products[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;
        },
        createId() {
            let id = '';
            var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            for ( var i = 0; i < 5; i++ ) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return id;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        confirmDeleteSelected() {
            this.deleteProductsDialog = true;
        },
        deleteSelectedProducts() {
            this.products = this.products.filter(val => !this.selectedProducts.includes(val));
            this.deleteProductsDialog = false;
            this.selectedProducts = null;
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
        async getCep() {
            const zipcode = this.customer.address.zipcode ? this.customer.address.zipcode.replace('-', '') : ''
            var zipcodeValidate = /^[0-9]{8}$/;

            if (zipcode.length <= 7) {
                return false
            }

            if (!zipcodeValidate.test(zipcode)) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'CEP Inválido!', life: 5000 });
                this.search_zipcode = false
                this.customer.address = {}
                return
            }

            this.search_zipcode = true
            const request = await fetch(`https://viacep.com.br/ws/${zipcode}/json/`, {
                method: 'GET',
                mode: "cors",
                cache: "default",
            })
            const address = await request.json()

            if (address.erro) {
                this.$toast.add({ severity: 'warn', summary: 'Info', detail: 'CEP não encontrado!', life: 5000 });
                this.search_zipcode = false
                return
            }

            this.customer.address.city          = address.localidade ?? ''
            this.customer.address.street        = address.logradouro ?? ''
            this.customer.address.neighborhood  = address.bairro ?? ''
            this.customer.address.state         = address.uf ?? ''
            this.customer.address.zipcode       = zipcode ?? ''

            setTimeout(() => {
                this.search_zipcode = false
            }, 800);
        },
        addNewContact() {
            this.customer.contacts.push({type: '', contact: ''})
            this.contact_fields++
        },
        removeContact(index) {
            this.customer.contacts.splice(index, 1);
            this.contact_fields--
        },
        beforeUpload() {
            this.import_customers = true
        },
        errorUpload() {
            this.$swal.fire('Erro na importação!', 'Tente importar novamente', 'error')
            this.import_customers = false

            customersService.allCustomers().then((response) => {
                this.customers = response.data
            })
        },
        onImportCustomers() {
            this.$swal.fire('Clientes importados com sucesso!', '', 'success')
            this.import_customers = false

            customersService.allCustomers().then((response) => {
                this.customers = response.data
            })
        },
        openNewContract(customer_id) {
            this.newContractDialog = true
            this.new_contract_data.customer = customer_id

            this.disabled_button = true
            this.new_contract_data.contract_type = null

            this.contracts = []
            contractsModelsService.getAll().then((response) => {
                if (response.data.length >= 1) {
                    response.data.map((index) => {
                        this.contracts.push({
                            label: index.contract_model_name,
                            value: index.contract_model_id,
                            contract_type: index.contract_model_type
                        })
                    })
                }
            })
        },
        selectContractModel() {
            if (this.new_contract_data.contract_type) {
                this.disabled_button = false
            }
        },
        saveNewContract() {
            try {
                this.$swal.fire({
                    title: 'Novo Contrato',
                    html: 'Aguarde seu até que o contrato seja criado...',
                    didOpen: () => {
                        this.$swal.showLoading()
                    }
                })

                contractsService.createContract(this.new_contract_data).then((response) => {
                    console.log(response)
                    this.$swal.close()
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Contrato Criado com Sucesso!',
                        life: 3000
                    })
                    this.newContractDialog = false
                    this.new_contract_data = {
                        contract_type: null,
                        customer: null,
                    }
                })
            } catch(e) {
                console.log(e)
            }
        },
        editCustomer(data) {
            requestService.get('/customer/find', {
                customer_id: data.customer_id
            }).then((response) => {
                if (response.data.status !== 200) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro ao obter dados do cliente'
                    })
                    console.log(response)
                    return false
                }

                //console.log(response)
                this.customerData = response.data.customer
                this.customerInfoDialog = true
                console.log(this.customerData)
            })
        },
        closeNewCustomerDialog() {
            alert('OK')
            this.customer = {
                customer_type: 'pf',
                address: {
                    type_address: 'home'
                },
                contacts: [{contact_type: 'email', contact_detail: ''}]
            }
        },
        editCustomerVehicles(data) {
            requestService.get('/vehicle/select', {
                customer_id: data.customer_id
            }).then((response) => {
                if (response.status !== 200) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro ao obter veículos do cliente'
                    })
                    console.log(response)
                    return false
                }

                console.log(response)
                this.customerVehicles = response.data
                this.customerVehiclesDialog = true
            })
        }
    },
    mounted() {
        customersService.allCustomers().then((response) => {
            this.customers = response.data
        })

        this.states = locationService.getStatesList()
    },
    components: {
        Toolbar,
        Button,
        FileUpload,
        DataTable,
        Column,
        Tag,
        InputText,
        Dropdown,
        Dialog,
        Calendar,
        RadioButton,
        ProgressBar,
        SelectButton,
        Checkbox,
        InputSwitch
    }
}
</script>

<style lang="scss">
input[role="combobox"] {
    padding: 8px !important;
    border-radius: 6px !important;
}
.customer_type .p-highlight,
.type_address .p-highlight {
    background-color: #2563eb;
}
.checkActive {
    width: 18px;
    height: 18px;
}
</style>