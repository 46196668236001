<template>
    <div>
        <h2 class="font-bold text-md xl:text-xl text-blue-800 text-center mb-5">Segue os dados para pagamento do Boleto:</h2>

        <div class="flex flex-col items-center">
            <div class="text-center mb-10">
                <p class="mb-2 font-bold">Pagar Boleto com Pix</p>
                <img class="w-64 h-64" :src="this.paymentData.pix.qrcode_image" alt="">
            </div>

            <div class="text-center">
                <p class="font-bold mb-2">Código do Boleto</p>
                <div class="barcode mb-8 rounded-lg flex">
                    <span class="code border p-1 px-3 text-xs inline-block w-full xl:max-w-[500px] text-neutral-500 overflow-x-auto">{{ this.paymentData.barcode }}</span>
                    <span @click="copyCode" :class="copy ? `bg-green-600` : `bg-blue-700`" class="copy inline-block px-4 text-white flex items-center justify-center cursor-pointer">Copiar</span>
                </div>

                <div class="flex flex-wrap gap-2 justify-center">
                    <a
                        class="bg-blue-800 text-white p-2 px-6 inline-block rounded-lg shadow-lg flex items-center gap-1"
                        target="_blank"
                        :href="this.paymentData.link">
                        Informações do Boleto
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z" />
                        </svg>
                    </a>

                    <a
                        class="bg-red-600 text-white p-2 px-6 inline-block rounded-lg shadow-lg flex items-center gap-1"
                        target="_blank"
                        :href="this.paymentData.pdf.charge">
                        Boleto em PDF
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>

        <p class="text-center text-lg xl:text-2xl font-bold mt-8">Total do pedido: <span class="text-blue-800">{{ getTotalPrice() }}</span></p>
    </div>
</template>

<script>
import paymentService from '@/services/paymentService'

export default {
    name: 'Ticket',
    data() {
        return {
            paymentData: paymentService.getPaymentData() ?? null,
            copy: false
        }
    },
    props: {
        paymentData: Object
    },
    methods: {
        getTotalPrice(total) {
            return (this.paymentData.total / 100).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })
        },
        copyCode(e) {
            let texto = document.querySelector(".code")
            let areaTexto = document.createElement("textarea")

            this.copy = true
            e.target.innerText = 'Copiado!'

            areaTexto.value = texto.innerText
            document.body.appendChild(areaTexto)
            areaTexto.select()
            areaTexto.setSelectionRange(0, 99999)
            document.execCommand("copy")
            document.body.removeChild(areaTexto)

            setTimeout(() => {
                this.copy = false
                e.target.innerText = 'Copiar'
            }, 2000)
        }
    },
    mounted() {

    },
    components: {
        
    }
}
</script>

<style lang="scss">
.code {
    border-radius: 6px 0 0 6px;
}
.copy {
    border-radius: 0 6px 6px 0;
}
</style>