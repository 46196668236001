//import axios from "axios"
import router from '@/router'
import requestService from "./requestService"
import cartService from "./cartService"
import paymentService from "./paymentService"
import { authStore } from "@/store/authStore"

const request = requestService

export default {
    async getAll() {
        return await requestService.get('/order/all')
    },
    setOrderHash(hash) {
        localStorage.setItem('order_hash', hash)
    },
    getOrderHash() {
        return localStorage.getItem('order_hash')
    },
    resetOrder() {
        localStorage.removeItem('paymentData')
        localStorage.removeItem('order_hash')
        localStorage.removeItem('cart')
        localStorage.removeItem('payment')
        localStorage.removeItem('cart_timestamp')
    },
    async cancelOrder(order_hash) {
        const cancel_request = await requestService.post('/customer-order/cancel', {
            order_hash: order_hash
        })
        return cancel_request
    },
    async requestNewOrder() {
        const cart = cartService.getItemsCart()
        const customer = authStore()

        // Atualiza Pedido
        if (this.getOrderHash() && typeof this.getOrderHash !== 'undefined') {
            try {
                const order = await request.post('/customer-order/update', {
                    customer_id: customer.getCustomerData.id,
                    order_hash: this.getOrderHash(),
                    cart: cart,
                    payment_method: paymentService.getPaymentMethod()
                })

                paymentService.deletePaymentData()
                paymentService.setPaymentData(order.data.payment.original.data)
                router.push(`/area-cliente/pedido/${this.getOrderHash()}/pagamento`)

                return order
            } catch(e) {
                console.log(e)
            }

            return true
        }

        // Cria Novo Pedido
        try {
            const order = await request.post('/customer-order/new', {
                customer_id: customer.getCustomerData.id,
                cart: cart,
                payment_method: paymentService.getPaymentMethod()
            })

            paymentService.deletePaymentData()

            switch (paymentService.getPaymentMethod()) {
                case 'ticket':
                    paymentService.setPaymentData(order.data.payment.original.original.data)
                    break;
                case 'pix':
                    paymentService.setPaymentData({
                        pix: order.data.payment.original.pix,
                        qrcode: order.data.payment.original.qrcode
                    })
                    break;
                case 'credit_card':
                    paymentService.setPaymentData()
                    break;
            }

            if (this.getOrderHash() && typeof this.getOrderHash !== 'undefined') {
                router.push(`/area-cliente/pedido/${this.getOrderHash()}/pagamento`)
                return
            }

            this.setOrderHash(order.data[0].order_hash)
            router.push(`/area-cliente/pedido/${order.data[0].order_hash}/pagamento`)

            return order
        } catch(e) {
            console.log(e)
        }
    },
    async checkOrder(hash) {
        return await request.get('/customer-order/check', {
            hash: hash
        })
    }
}
