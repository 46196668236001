import requestService from "./requestService"
const request = requestService

export default {
    async getAll() {
        return await request.get('/customer-vehicle/all')
    },
    async getByCustomer(customer_id) {
        return await request.get('/customer-vehicle/select', {
            customer_id: customer_id
        })
    },
    newVehicle(data) {
        return request.post('/vehicle/new', data)
    }
}