import dateService from '@/services/dateService'

export default {
    setItemsCart(item) {
        localStorage.setItem('cart', JSON.stringify(item))
    },
    setTimestampCart(data) {
        localStorage.setItem('cart_timestamp', JSON.stringify({timestamp: Date.now()}))
    },
    getItemsCart() {
        let items = JSON.parse(localStorage.getItem('cart'))
        return items
    },
    getTotalCart() {
        let items = this.getItemsCart()
        let prices = []

        if (items === null) {
            return 0
        }

        items.map((value, index) => {
            prices.push(parseFloat(value.plan_price))
            prices.push(parseFloat(value.activation_rate))
            prices.push(parseFloat(value.equipment_rate))
        })

        return prices.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    },
    emptyCart() {
        localStorage.removeItem('cart')
    },
    validateCartTimestamp() {
        let timestamp = JSON.parse(localStorage.getItem('cart_timestamp'))
        
        // Caso não exista o timestamp, retorna true para que o redirecionamento possa acontecer
        if (!timestamp) {
            return true
        }

        let now = Date.now()
        return dateService.differenceInMinutes(timestamp.timestamp, now) > 1500
    },
    deleteCartTimestamp() {
        localStorage.removeItem('cart_timestamp')
    }
}