export default {
    formatBR(datetime) {
        const date = new Date(datetime)
        return date.toLocaleDateString('pt-BR', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        })
    },
    formatTimestamp(timestamp) {
        const date = new Date(timestamp);
    
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hour = ('0' + date.getHours()).slice(-2);
        const minute = ('0' + date.getMinutes()).slice(-2);
        const second = ('0' + date.getSeconds()).slice(-2);
    
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    },
    differenceInDays(timestamp1, timestamp2) {
        const date1 = new Date(timestamp1);
        const date2 = new Date(timestamp2);

        const differenceMs = Math.abs(date2 - date1);
        const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    
        if (date2 < date1) {
            return -1
        }

        return differenceDays;
    },
    differenceInMinutes(timestamp1, timestamp2) {
        const date1 = new Date(timestamp1);
        const date2 = new Date(timestamp2);
    
        const differenceMs = Math.abs(date2 - date1);
        const differenceMinutes = Math.floor(differenceMs / (1000 * 60));
    
        return differenceMinutes;
    }
}