import requestService from "./requestService"

export default {
    async createContract(data) {
        return await requestService.post('/contracts/new-contract-prepaid', data)
    },
    async getAll(customer_id) {
        return await requestService.get('/customer-contracts/all', {
            customer_id: customer_id
        })
    },
    async getHiringTerm() {
        return await requestService.get('/customer-contracts/get-model-slug', {
            model_type: 'hiring_term'
        })
    }
}