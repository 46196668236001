<template>
    <main>
        <div class="bg-gray-100 h-screen flex items-center justify-center">
            <div class="container mx-auto">
                <div class="login-container ">
                    <div class="bg-white border p-8 mt-4 shadow-xl rounded-xl max-w-sm mx-auto">
                        <img class="mx-auto max-w-[120px]" src="@/assets/logo-colorida.png" alt="">
                        <h2 class="text-md font-bold text-center my-2 text-gray-400">Dashboard Administrativa</h2>

                        <hr class="block mb-4">

                        <form @submit.prevent="submitForm">
                            <div class="p-field mb-5">
                                <label for="username" class="text-sm font-bold">Email</label>
                                <InputText type="email" id="email-login-dashboard" class="block w-full p-2 border rounded-xl" placeholder="Seu E-mail" v-model="email" />
                            </div>

                            <div class="p-field mb-5">
                                <label for="password" class="text-sm font-bold">Senha</label>
                                <Password v-model="password" id="password-login-dashboard" :feedback="false" placeholder="*********" class="input-password block w-full p-2 border rounded-xl" toggleMask/>
                            </div>

                            <Button type="submit" class="py-2 px-5 w-full rounded-xl bg-blue-800 hover:bg-blue-900 text-white shadow-none" label="Entrar" />

                            <hr class="block my-4 hidden">

                            <RouterLink to="/reset-password" class="hidden text-sm font-bold text-center block text-blue-800">Esqueceu a senha?</RouterLink>
                        </form>
                    </div>

                    <Toast></Toast>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Toast from 'primevue/toast'
import Password from 'primevue/password'
import axios from 'axios'
import { RouterLink } from 'vue-router'
import { authStore } from '@/store/authStore'
import authService from '@/services/authService'

export default {
    name: 'dashboard-login-view',
    data() {
        return {
            email: null,
            password: null,
        }
    },
    components: {
        InputText, Button, Toast, Password
    },
    methods: {
        async submitForm() {
            const store = authStore()

            if (!this.email || !this.password) {
                this.$toast.add({ severity: 'warn', summary: 'Campos Vazios!', detail: 'Por favor, preencha todos os campos..', life: 3000 });
                return false;
            }

            try {
                await axios.post('/dashboard/login', {
                    email: this.email,
                    password: this.password
                }).then((response) => {
                    if (response.data.status !== 200) {
                        switch (response.data.status) {
                            case 401:
                                this.$toast.add({ severity: 'error', summary: 'Dados Incorretos!', detail: 'Por favor, tente novamente..', life: 3000 });
                                break
                            case 402:
                                this.$toast.add({ severity: 'warn', summary: 'Acesso não permitido!', detail: 'Você não tem permissão para acessar esta área...', life: 3000 });
                                break
                        }
                        return false
                    }

                    store.setToken(response.data.accessToken)
                    store.setExpireToken(response.data.expires_in)
                    store.setUserData(response.data.userData)
                    this.$toast.add({ severity: 'success', summary: 'Login Realizado com Sucesso!', detail: 'Aguarde... Você será redirecionado.', life: 3000 });
                    setTimeout(() => {
                        document.location.reload()
                    }, 1000)
                })
            } catch (error) {
                console.log(error)
            }
        },
        show() {
            this.$toast.add({ severity: 'info', summary: 'Info', detail: 'Message Content', life: 3000 });
        },
        async isAuthenticated() {
            const request = await authService.isAuthenticated()
        
            if (!request) {
                return
            }

            if (request.request.status && request.request.status === 200) {
                this.$router.push('/painel')
            }
        }
    },
    mounted() {
        this.isAuthenticated()
    }
}
</script>

<style lang="scss">
#password-login-dashboard {
    padding: 0 !important;
}
#password-login-dashboard .p-inputtext {
    width: 100%;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    border-radius: 0.75rem;
}
</style>