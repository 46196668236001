<template>
    <aside class="sidebar-dashboard py-10 px-5 2xl:p-10 bg-white min-h-screen">
        <img class="w-[150px] mx-auto" src="@/assets/logo-colorida.png" alt="">

        <hr class="mt-6">

        <div class="nav-list flex mt-10 2xl:px-8 flex-col gap-2 flex-wrap text-sm text-blue-800">
            <RouterLink to="/painel"><i class="fa-solid fa-house"></i> Dashboard</RouterLink>
            <RouterLink to="/painel/clientes"><i class="fa-solid fa-users"></i> Clientes</RouterLink>
            <RouterLink to="/painel/veiculos"><i class="fa-solid fa-car"></i> Veículos</RouterLink>
            <RouterLink to="/painel/pedidos"><i class="fa-solid fa-cash-register"></i> Pedidos</RouterLink>
            <RouterLink to="/painel/modelos-contratos"><i class="fa-solid fa-file-contract"></i> Modelos de Contrato</RouterLink>
            <RouterLink to="/painel/relatorios"><i class="fa-solid fa-chart-pie"></i> Relatórios</RouterLink>
            <RouterLink to="/painel/configuracoes"><i class="fa-solid fa-gear"></i> Configurações</RouterLink>
        </div>
    </aside>
</template>

<script>
export default {
    name: 'sidebar-component'
}
</script>

<style lang="scss" scoped>
.nav-list {
    padding: 0 !important;
    i {
        font-size: 1.2rem;
        width: 25px;
        text-align: center;
    }
    a {
        flex-grow: 1;
        display: block;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 15px;
        border-radius: 6px;
        transition: all .2s;
        &:hover {
            background: #eeeeee;
        }
        &.router-link-active {
            background: #2563eb;
            color: #ffffff;
            box-shadow: 1px 1px 12px 1px rgba(0,0,0,.4);
        }
    }
}
</style>