<template>
    <div>
        <div class="hirings text-neutral-600 pb-4 mb-5 last:mb-0 border-b-[1px] border-neutral-200 last:border-b-0" v-for="vehicle in allVehicles" :key="vehicle.vehicle_id">
            <!-- Hiring Exists -->
            <div class="hiring block lg:flex justify-between items-center flex-wrap gap-10" v-if="vehicle.hiring_status">
                <div class="text-2xl text-blue-800">
                    <i class="fa-solid fa-motorcycle" v-if="vehicle.vehicle_type === 'Motocicleta'"></i>
                    <i class="fa-solid fa-car" v-else-if="vehicle.vehicle_type === 'Passeio'"></i>
                    <i class="fa-solid fa-truck" v-else-if="vehicle.vehicle_type === 'Caminhão'"></i>
                    <i class="fa-solid fa-circle-question" v-else></i>
                </div>
                
                <!-- Hiring Details -->
                <div class="grow text-sm flex justify-start items-center flex-wrap gap-10">
                    <div class="min-w-[13%]">
                        <p><b>Veículo:</b> {{ vehicle.model }}</p>
                        <p><b>Ano:</b> {{ vehicle.year }}</p>
                        <p><b>Cor:</b> {{ vehicle.color.toUpperCase() }}</p>
                        <p><b>Placa:</b> {{ vehicle.plate }}</p>
                        <p><b>Valor:</b> {{ getTotalPrice(vehicle.hiring_price ?? 0.00) }}</p>
                    </div>

                    <div>
                        <p><i class="w-4 text-end fa-solid fa-signal"></i> <b>Status:</b> <span class="text-green-500 font-bold">CONTRATADO</span></p>
                        <p><i class="w-4 text-end fa-solid fa-calendar-days"></i> <b>Início da Contratação:</b> {{ dateService().formatBR(vehicle.hiring_start) }}</p>
                        <p><i class="w-4 text-end fa-solid fa-calendar-days"></i> <b>Fim da Contratação:</b> {{ dateService().formatBR(vehicle.hiring_end) }}</p>
                        <p><i class="w-4 text-end fa-solid fa-hourglass-half"></i> Faltam <b>{{ hiringDaysLeft(vehicle.hiring_end) + 1 }}</b> dias para encerrar seu plano</p>
                    </div>

                    <div class="grow text-md text-end">
                        <button type="button" class="bg-blue-800 text-white rounded-lg py-1 px-3 btn-toggle-plus"><i class="fa-solid fa-plus"></i> Detalhes</button>
                    </div>
                </div>

                <!-- Hiring Details Plus Toggle -->
                <div class="toggle-details w-full bg-orange-100 text-neutral-600 p-4 rounded-lg text-sm hidden">
                    <div class="leading-6">
                        <p><i class="fa-solid fa-bag-shopping"></i> <b>Plano Contratado:</b> {{ vehicle.plan_name }}</p>
                        <p><i class="fa-solid fa-dollar-sign"></i> <b>Valor do Plano:</b> {{ getTotalPrice(vehicle.plan_price) }}</p>
                        <p v-if="vehicle.payment_method === 'credit_card'"><i class="fa-solid fa-cash-register"></i> <b>Método de Pagamento:</b> Cartão de Crédito</p>
                        <p v-else-if="vehicle.payment_method === 'pix'"><i class="fa-solid fa-cash-register"></i> <b>Método de Pagamento:</b> Pix</p>
                        <p v-else><i class="fa-solid fa-cash-register"></i> <b>Método de Pagamento:</b> Boleto</p>
                        <p><i class="fa-solid fa-calendar-days"></i> <b>Data do Pagamento:</b> {{ dateService().formatBR(vehicle.payment_date) }}</p>
                        <p><i class="fa-solid fa-money-check-dollar"></i> <b>Status do Pagamento:</b> PAGO</p>
                        <p><i class="fa-solid fa-money-check-dollar"></i> <b>Total:</b> {{ getTotalPrice(vehicle.payment_total) }}</p>
                    </div>
                </div>
            </div>
            <!-- End Hirings Exists -->

            <!-- Not Hiring Exists -->
            <div class="block lg:flex justify-between items-center gap-5 2xl:gap-10 text-neutral-600" v-else>
                <div class="text-2xl text-blue-800">
                    <i class="fa-solid fa-motorcycle" v-if="vehicle.vehicle_type === 'Motocicleta'"></i>
                    <i class="fa-solid fa-car" v-else-if="vehicle.vehicle_type === 'Passeio'"></i>
                    <i class="fa-solid fa-truck" v-else-if="vehicle.vehicle_type === 'Caminhão'"></i>
                    <i class="fa-solid fa-circle-question" v-else></i>
                </div>

                <div class="text-sm grow max-w-[160px]">
                    <p><b>Veículo:</b> {{ vehicle.model }}</p>
                    <p><b>Ano:</b> {{ vehicle.year }}</p>
                    <p><b>Cor:</b> {{ vehicle.color }}</p>
                    <p><b>Placa:</b> {{ vehicle.plate }}</p>
                </div>
                
                <div class="boxes block lg:flex items-center justify-between gap-4 grow text-sm">
                    <div class="cursor-pointer grow border rounded-lg p-4 py-6 my-3 lg:my-0 text-center text-lg plan"
                        :id="`plan-${vehicle.renavam}-${plan.plan_id}`"
                        @click="addCart(`plan-${vehicle.renavam}-${plan.plan_id}`)"
                        v-for="plan in allPlans" :key="plan.plan_id"
                        :data-vehicle-id="vehicle.vehicle_id"
                        :data-plan-id="plan.plan_id"
                        :data-plan-name="plan.plan_name"
                        :data-plan-months-period="plan.plan_months_period"
                        :data-plan-price="plan.plan_price"
                        :data-vehicle-model="vehicle.model"
                        :data-vehicle-type="vehicle.type"
                        :data-active-equipment-rate="vehicle.active_equipment_rate"
                        :data-equipment-rate="vehicle.active_equipment_rate ? vehicle.equipment_rate : 0"
                        :data-active-activation-rate="vehicle.active_activation_rate"
                        :data-activation-rate="vehicle.active_activation_rate ? vehicle.activation_rate : 0"
                    >
                        <p class="font-bold text-blue-800 plan-name">{{ plan.plan_name }}</p>
                        <p class="font-bold leading-none plan-price">{{ getTotalPrice(plan.plan_price) }}</p>
                        <span class="block text-center text-sm plan-period">{{ getTotalPrice(plan.plan_price / plan.plan_months_period) }} ao mês</span>
                    </div>
                </div>

                <div class="block lg:flex flex-col gap-2 mt-4 lg:mt-0">
                    <p class="bg-yellow-100 my-2 lg:my-0 p-2 py-1 rounded-lg text-sm border border-yellow-300 flex flex-col" v-if="vehicle.active_equipment_rate"><span>Valor do Equipamento:</span> <b>{{ getTotalPrice(vehicle.equipment_rate) }}</b></p>
                    <p class="bg-yellow-100 my-2 lg:my-0 p-2 py-1 rounded-lg text-sm border border-yellow-300 flex flex-col" v-if="vehicle.active_activation_rate"><span>Taxa de Ativação:</span> <b>{{ getTotalPrice(vehicle.activation_rate) }}</b></p>
                </div>
            </div>
        </div>

        <p class="total-order text-end text-lg hidden">Total da contratação: <b>{{ getTotalPrice(totalOrder) }}</b></p>
    </div>
</template>

<script>
import $ from 'jquery'
import vehiclesService from '@/services/vehiclesService'
import hiringsService from '@/services/hiringsService'
import plansService from '@/services/plansService'
import cartService from '@/services/cartService'
import dateService from '@/services/dateService'
import customersService from '@/services/customersService'

export default {
    name: 'Hirings Customer Dashboard',
    data() {
        return {
            cart: [],
            allVehicles: null,
            allHirings: null,
            allPlans: null,
            totalOrder: 0,
        }
    },
    methods: {
        getTotalPrice(price) {
            return price.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
            })
        },
        dateService() {
            return dateService
        },
        hiringDaysLeft(datetime) {
            const now = Date.now()
            return this.dateService().differenceInDays(now, datetime)
        },
        toggleClass(plan) {
            const elements = plan.parentNode.children
            
            for (let i = 0; i < elements.length; i++) {
                if (elements[i].id !== plan.id) {
                    elements[i].classList.remove('selected')
                }
            }

            plan.classList.toggle('selected')
        },
        addCart(id) {
            const plan = document.getElementById(id)
            let cart = []

            this.toggleClass(plan)

            const selecteds = document.querySelectorAll('.plan.selected')
            selecteds.forEach((elements, index) => {
                cart.push({
                    vehicle_id: elements.getAttribute('data-vehicle-id'),
                    vehicle_model: elements.getAttribute('data-vehicle-model'),
                    vehicle_type: elements.getAttribute('data-vehicle-type'),
                    plan_id: elements.getAttribute('data-plan-id'),
                    plan_name: elements.getAttribute('data-plan-name'),
                    plan_months: elements.getAttribute('data-plan-months-period'),
                    plan_price: elements.getAttribute('data-plan-price'),
                    active_equipment_rate: elements.getAttribute('data-active-equipment-rate'),
                    equipment_rate: elements.getAttribute('data-equipment-rate'),
                    active_activation_rate: elements.getAttribute('data-active-activation-rate'),
                    activation_rate: elements.getAttribute('data-activation-rate')
                })
            })

            cartService.setItemsCart(cart)
            this.totalOrder = cartService.getTotalCart()
            
            if (selecteds.length > 0) {
                $('.wrapper-buttons, .total-order').fadeIn(100)
                document.documentElement.scrollTop = document.documentElement.scrollHeight
            } else {
                document.documentElement.scrollTop = 0
                $('.wrapper-buttons, .total-order').fadeOut(100)
            }
        },
        functionShowNextButton() {
            $('#button-next').show()
        },
        async getAllVehicles() {
            const customer = JSON.parse(localStorage.getItem('customerData'))
            const all = await vehiclesService.getByCustomer(customer.id)

            if (!all) {
                document.location.reload()
                return false
            }

            console.log(all.data)
            this.allVehicles = all.data
        },
        async getAllHirings() {
            const all = await hiringsService.getAll()

            if (!all) {
                document.location.reload()
                return false
            }

            let hiringsStatus = []
            all.data.map((index) => {
                hiringsStatus.push({
                    status: index.hiring_status,
                    date: index.hiring_end
                })
            })

            hiringsStatus.map((index) => {
                if (index.status === 1 && this.hiringDaysLeft(index.date) < 0) {
                    document.location.reload()
                }
            })

            this.allHirings = all.data
        },
        async getAllPlans() {
            const all = await plansService.getAll()

            if (!all) {
                document.location.reload()
                return false
            }

            this.allPlans = all.data
        }
    },
    beforeMount() {
        this.getAllHirings()
    },
    mounted() {
        this.getAllVehicles()
        this.getAllPlans()

        $(document).on('click', '.btn-toggle-plus', function() {
            $(this).toggleClass('active')

            if ($(this).hasClass('active')) {
                $(this).closest('.hiring').find('.toggle-details').slideDown(300)
                $(this).find('i').toggleClass('fa-plus fa-minus')
            } else {
                $(this).closest('.hiring').find('.toggle-details').slideUp(300)
                $(this).find('i').toggleClass('fa-plus fa-minus')
            }
        })
    },
    components: {
    },
}
</script>

<style lang="scss" scoped>
.plan.selected {
    background: #1e40af;
    .plan-name {
        color: yellow;
    }
    p, span {
        color: #ffffff;
    }
}
</style>