<template>
    <div>
        <h2 class="font-bold text-sm xl:text-xl text-blue-800 text-center mb-5">Segue os dados do Pix para pagamento:</h2>

        <div class="flex flex-col items-center">
            <div class="text-center mb-10">
                <p class="mb-2 font-bold">Pagar com Qrcode</p>
                <img class="w-64 h-64" :src="this.imagemQrcode" alt="">
            </div>

            <div class="text-center">
                <p class="font-bold mb-2">Código do Pix</p>
                <div class="barcode mb-8 rounded-lg flex flex-wrap justify-center">
                    <span class="code border p-1 px-3 inline-block text-neutral-500 max-w-[15%] xl:max-w-[500px] overflow-x-auto">{{ this.qrcode }}</span>
                    <span @click="copyCode" :class="copy ? `bg-green-600` : `bg-blue-700`" class="copy inline-block px-4 text-white flex items-center justify-center cursor-pointer">Copiar</span>
                </div>

                <div class="flex flex-wrap gap-2 justify-center">
                    <a
                        class="text-blue-800 font-bold inline-block flex items-center gap-1"
                        target="_blank"
                        :href="this.linkPix">
                        Link de Pagamento
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>

        <p class="text-center text-lg xl:text-2xl font-bold mt-8">Total do pedido: <span class="text-blue-800">{{ getTotalPrice() }}</span></p>

        <!-- <div class="flex justify-center mt-4">
            <p class="text-center text-sm bg-yellow-200 inline-block p-1 px-2 rounded-lg border border-yellow-300 max-w-[370px]">Após o pagamento, aguarde até <b>5 minutos</b> para confirmação do pagamento.</p>
        </div> -->
    </div>
</template>

<script>
import paymentService from '@/services/paymentService'

export default {
    name: 'Pix',
    data() {
        return {
            qrcode: null,
            imagemQrcode: null,
            linkPix: null,
            valorTotal: '0.00',
            copy: false,
            payment_finish: false
        }
    },
    methods: {
        getTotalPrice() {
            return (this.valorTotal / 100).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })
        },
        copyCode(e) {
            let texto = document.querySelector(".code")
            let areaTexto = document.createElement("textarea")

            this.copy = true
            e.target.innerText = 'Copiado!'

            areaTexto.value = texto.innerText
            document.body.appendChild(areaTexto)
            areaTexto.select()
            areaTexto.setSelectionRange(0, 99999)
            document.execCommand("copy")
            document.body.removeChild(areaTexto)

            setTimeout(() => {
                this.copy = false
                e.target.innerText = 'Copiar'
            }, 2000)
        },
        checkPymentPix() {
            const data = paymentService.getPaymentData()

            const check = setInterval(() => {
                if (this.payment_finish) {
                    clearInterval(check)
                    return false
                }

                paymentService.checkPayment('pix', data.pix.txid).then((response) => {
                    if (response.data.status === 'CONCLUIDA') {
                        this.payment_finish = true
                        this.$swal.fire('Pagamento Recebido com Sucesso!', 'Seu pagamento via Pix foi recebido com sucesso! Aguarde...', 'success')
                        setTimeout(() => {
                            document.location.href = '/area-cliente'
                        }, 3000)
                    }
                })
            }, 2000)
        }
    },
    mounted() {
        const data = paymentService.getPaymentData()

        this.qrcode = data.qrcode.qrcode
        this.imagemQrcode = data.qrcode.imagemQrcode
        this.linkPix = data.qrcode.linkVisualizacao
        this.valorTotal = data.pix.valor.original.replace('.', '')
        this.checkPymentPix()
    },
}
</script>

<style lang="scss" scoped>
.code {
    white-space: nowrap;
    &::-webkit-scrollbar {
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0,0,0,.4);
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
</style>
