import requestService from "./requestService"

const request = requestService

export default {
    async allCustomers() {
        const customers = await request.get('/customer/all')
        return customers
    },
    setLocalCustomers(customers) {
        localStorage.setItem('customers', JSON.stringify(customers))
    },
    removeLocalCustomers() {
        localStorage.removeItem('customers')
    },
    async getLocalCustomers() {
        const local_customers = localStorage.getItem('customers')
    
        if (!local_customers) {
            const remote_customers = await request.get('/customer/all')
            
            this.setLocalCustomers(remote_customers.data)
            return remote_customers.data
        }

        return JSON.parse(local_customers)
    },
    async setCustomerStatus(customer, status)
    {
        return await request.post('/customer/update', {
            customer_id: customer,
            data: {
                active: status
            }
        })
    }
}