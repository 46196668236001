<template>
    <DataTable
        ref="dt"
        :value="reports"
        class="text-sm shadow"
        v-model:selection="selectedReports"
        dataKey="customer_id"
        :paginator="true"
        :rows="100"
        :rowsPerPageOptions="[1, 5,10,25,50,100,200,500,1000,2000,3000]"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} clientes">

        <template #header>
            <div class="flex flex-wrap gap-8 items-center justify-content-between">
                <IconField iconPosition="left">
                    <InputIcon>
                        <i class="pi pi-search me-3" />
                    </InputIcon>

                    <InputText class="bg-white p-3 border" v-model="filters['global'].value" placeholder="Buscar Contratações..." />
                </IconField>
            </div>
        </template>

        <Column selectionMode="multiple" :exportable="false"></Column>

        <Column header="ID da Contratação" sortable>
            <template #body="slotProps">
                {{ slotProps.data.hiring_id }}
            </template>
        </Column>

        <Column field="name" header="Cliente" sortable></Column>

        <Column header="Plano" sortable>
            <template #body="slotProps">
                {{ slotProps.data.plan_name }}
            </template>
        </Column>

        <Column header="Valor do Plano" sortable>
            <template #body="slotProps">
                {{ getPrice(slotProps.data.plan_price) }}
            </template>
        </Column>

        <Column field="hiring_status" header="Status da Contratação" sortable>
            <template #body="slotProps">
                <span v-if="slotProps.data.hiring_status === 0" class="bg-red-400 p-1 text-xs text-white rounded">Expirada</span>
                <span v-else class="p-1 bg-green-500 text-white text-xs rounded">Ativa</span>
            </template>
        </Column>

        <Column header="Tempo Restante" sortable>
            <template #body="slotProps">
                <span v-if="slotProps.data.hiring_status === 0" class="font-bold">0</span>
                <span v-else class="p-1 bg-green-500 text-white text-xs rounded">{{ calcularDiasRestantes(slotProps.data.hiring_end) }} dias</span>
            </template>
        </Column>

        <Column header="Data da Expiração" sortable>
            <template #body="slotProps">
                <span v-if="slotProps.data.hiring_status === 0" class="">{{ getDate(slotProps.data.hiring_end) }}</span>
                <span v-else class="text-neutral-400">---------------------</span>
            </template>
        </Column>
    </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import InputIcon from 'primevue/inputicon'
import Checkbox from 'primevue/checkbox'
import { FilterMatchMode } from 'primevue/api'
import requestService from '@/services/requestService'

export default {
    name: 'TableReport',
    components: {
        DataTable, Column, InputIcon, InputText, Checkbox
    },
    data() {
        return {
            filters: {},
            reports: [],
            selectedReports: [],
            count: [],
        }
    },
    methods: {
        getReports() {
            requestService.get('reports').then((response) => {
                this.reports = response.data
            })
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                active: { value: null, matchMode: FilterMatchMode.CONTAINS },
                expired: { value: null, matchMode: FilterMatchMode.CONTAINS },
            }
        },
        getCountHiringsActive(hirings) {
            let count = 0;
            hirings.map((index) => {
                if (index.hiring_status === 1) {
                    count++
                }                
            })

            return count + ' contratações ativas'
        },
        getCountHiringsInactive(hirings) {
            let count = 0;
            hirings.map((index) => {
                if (index.hiring_status === 0) {
                    count++
                }                
            })

            return count + ' contratações inativas'
        },
        getLastExpiredHiring(hirings) {
            if (hirings.length < 1) {
                return false
            }

            let expireds = []
            hirings.map((index) => {
                if (index.hiring_status === 0) {
                    expireds.push(index)
                }                
            })

            let last = this.getDate(expireds[expireds.length - 1].hiring_end)
            return last;
        },
        getDate(dataString) {
            let data = new Date(dataString);
            let dia = data.getDate();
            let mes = data.getMonth() + 1;
            let ano = data.getFullYear();
            let hora = data.getHours();
            let minuto = data.getMinutes();
            let segundo = data.getSeconds();

            if (dia < 10) {
                dia = '0' + dia;
            }
            if (mes < 10) {
                mes = '0' + mes;
            }
            if (hora < 10) {
                hora = '0' + hora;
            }
            if (minuto < 10) {
                minuto = '0' + minuto;
            }
            if (segundo < 10) {
                segundo = '0' + segundo;
            }

            let dataFormatada = dia + '/' + mes + '/' + ano + ' ' + hora + ':' + minuto + ':' + segundo;

            return dataFormatada;
        },
        calcularDiasRestantes(dataEspecifica) {
            let dataAlvo = new Date(dataEspecifica);
            
            if (isNaN(dataAlvo.getTime())) {
                return 'Data inválida';
            }

            let dataAtual = new Date();

            let diferenca = dataAlvo.getTime() - dataAtual.getTime();

            let diasRestantes = Math.floor(diferenca / (1000 * 60 * 60 * 24));

            return diasRestantes;
        },
        getPrice(price) {
            return price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        }
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.getReports()
    }
}
</script>