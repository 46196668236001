<template>
  <RouterView></RouterView>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
</style>
