<template>
    <div class="flex gap-5">
        <div class="card box p-8 shadow-lg flex-grow rounded-lg border border-b-4 border-b-blue-500 bg-white">
            <p class="text-sm font-bold flex items-center"><i class="pi pi-users me-1 text-2xl"></i> Total de Clientes</p>
            <span class="font-extra-bold text-4xl 2xl:text-6xl text-blue-500 mt-4 block">{{ data.customers }}</span>
        </div>

        <div class="card box p-8 shadow-lg flex-grow rounded-lg border border-b-4 border-b-red-400 bg-white">
            <p class="text-sm font-bold flex items-center"><i class="pi pi-briefcase me-1 text-2xl"></i> Total de Contratações</p>
            <span class="font-extra-bold text-4xl 2xl:text-6xl text-red-400 mt-4 block">{{ data.hirings }}</span>
        </div>

        <div class="card box p-8 shadow-lg flex-grow rounded-lg border border-b-4 border-b-purple-500 bg-white">
            <p class="text-sm font-bold flex items-center"><i class="pi pi-calendar me-1 text-2xl"></i> Contratações em {{ month }}/{{ year }}</p>
            <span class="font-extra-bold text-4xl 2xl:text-6xl text-purple-500 mt-4 block">{{ data.hirings_month }}</span>
        </div>

        <div class="card box p-8 shadow-lg flex-grow rounded-lg border border-b-4 border-b-green-500 bg-white">
            <p class="text-sm font-bold flex items-center"><i class="pi pi-car me-1 text-2xl"></i> Total de Veículos</p>
            <span class="font-extra-bold text-4xl 2xl:text-6xl text-green-500 mt-4 block">{{ data.customer_vehicles }}</span>
        </div>
    </div>
</template>

<script>
const date = new Date()

export default {
    name: 'boxes-count-component',
    props: {
        data: Object
    },
    data() {
        return {
            month: date.getMonth()+1,
            year: date.getFullYear()
        }
    }
}
</script>

<style lang="scss" scoped>
</style>