<template>
    <div class="vehicles">
        <div class="flex dashboard-wrapper">
            <div class="w-[15%] bg-slate-100">
                <SidebarDashboard/>
            </div>

            <div class="w-[85%]">
                <HeaderDash/>

                <div class="dashboard-content p-5 px-10 pb-20 min-h-screen bg-white">
                    <h2 class="mb-4 font-bold text-3xl">{{ currentTitle }}</h2>

                    <RouterLink
                        class="rounded-md mt-3 inline-block p-3 px-4 bg-green-500 text-white"
                        :to="{name: 'new-contract-model'}"
                    >
                        <i class="pi pi-plus"></i> Novo Modelo
                    </RouterLink>

                    <hr class="my-6">

                    <div class="">
                        <p class="font-bold mb-3">Todos os Contratos</p>

                        <div class="bg-white p-5 shadow-md rounded-md flex flex-wrap justify-between items-center mb-3 last:mb-0" v-for="contract in contracts" :key="contract_model_id">
                            <div class="text-[.8rem]">
                                <p><b class="text-blue-600">Nome:</b> {{ contract.contract_model_name }}</p>
                                <p><b class="text-blue-600">Tipo:</b> {{ contractType(contract.contract_model_type) }}</p>
                                <p><b class="text-blue-600">Criado em:</b> {{ contractDate(contract.created_at) }}</p>
                                <p><b class="text-blue-600">Última atualização:</b> {{ contractDate(contract.created_at) }}</p>
                            </div>

                            <div class="flex flex-col gap-2">
                                <RouterLink
                                    class="bg-blue-600 text-white p-1 px-3 text-xs rounded"
                                    :to="`/painel/modelos-contratos/edit/${contract.contract_model_id}`
                                ">
                                    <i class="pi pi-pencil text-xs me-1"></i>Editar Modelo
                                </RouterLink>

                                <Button
                                    label="Excluir Modelo"
                                    icon="pi pi-trash"
                                    class="bg-red-400 text-white p-1 px-3 text-xs rounded"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
                    <div class="confirmation-content">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span v-if="customer">Are you sure you want to delete the selected products?</span>
                    </div>
                    <template #footer>
                        <Button label="No" icon="pi pi-times" text @click="deleteProductsDialog = false"/>
                        <Button label="Yes" icon="pi pi-check" text @click="deleteSelectedProducts" />
                    </template>
                </Dialog>

                <Footer></Footer>
            </div>
        </div>

        <Toast/>
    </div>
</template>

<script>
import SidebarDashboard from '@/components/Dashboard/SidebarDashboard.vue'
import HeaderDash from '@/components/Dashboard/HeaderDashboard.vue'

import Button from 'primevue/button'
import Editor from 'primevue/editor'
import Dialog from 'primevue/dialog'

import contractsModelsService from '@/services/contractsModelsService'
import dateService from '@/services/dateService'

export default {
    name: 'Dashboard Contract Model',
    data() {
        return {
            currentTitle: "",
            contracts: [],
            delete_item: null,
            actions: [
                {
                    label: 'Excluir',
                    command: (item) => { this.delete(item) }
                },
            ]
        }
    },
    async mounted() {
        this.currentTitle = this.$route.meta.title
        this.$watch("$route", () => {
            this.currentTitle = this.$route.meta.title;
        })

        this.loadContracts()
    },
    methods: {
        loadContracts() {
            contractsModelsService.getAll().then((response) => {
                this.contracts = response.data
            })
        },
        contractType(type) {
            let typelabel = ""
            switch (type) {
                case 'contract_prepaid':
                    typelabel = "Contrato Pré-pago"
                    break
                case 'contract_postpaid':
                    typelabel = "Contrato Pós-pago"
                    break
                case 'hiring_term':
                    typelabel = "Termo de Contratação"
                    break
                case 'block_term':
                    typelabel = "Termo de Bloqueio"
                    break
                case 'installation_term':
                    typelabel = "Termo de Instalação"
                    break
            }

            return typelabel
        },
        contractDate(date) {
            return dateService.formatBR(date)
        },
        edit(model_id) {
            this.$router.push(`/painel/modelos-contratos/edit/${model_id}`)
        },
        delete(model_id) {
            console.log(model_id)
        }
    },
    components: {
        SidebarDashboard, HeaderDash, Button, Editor, Dialog
    }
}
</script>

<style lang="scss" scoped>

#preview-contract {
    margin: 25px 0 0 0;
    background: #ffffff;
    padding: 50px;
    border: 1px solid rgba(0,0,0,0.08);
}
</style>