<template>
    <div class="vehicles">
        <div class="flex dashboard-wrapper">
            <div class="w-[15%] bg-slate-100">
                <SidebarDashboard/>
            </div>

            <div class="w-[85%]">
                <Header></Header>

                <div class="dashboard-content p-5 px-10 pb-20 min-h-screen bg-white">
                    <h2 class="mb-4 font-bold text-3xl">{{ currentTitle }}</h2>

                    <TableOrdersDashboard/>
                </div>

                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarDashboard from '@/components/Dashboard/SidebarDashboard.vue'
import Header from '@/components/Dashboard/HeaderDashboard.vue'

import TableOrdersDashboard from '@/components/Dashboard/TableOrdersDashboard.vue'

export default {
    name: 'Dashboard Orders',
    data() {
        return {
            currentTitle: "",
        }
    },
    mounted() {
        this.currentTitle = this.$route.meta.title
        this.$watch("$route", () => {
            this.currentTitle = this.$route.meta.title;
        });
    },
    components: {
        SidebarDashboard, Header, TableOrdersDashboard
    }
}
</script>

<style lang="scss" scoped>

</style>