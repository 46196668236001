<template>
    <div class="relative">
        <h2 class="font-bold text-md xl:text-xl text-blue-800 text-center mb-5">Preencha os dados do cartão:</h2>

        <form method="POST" class="max-w-lg mx-auto">
            <div>
                <label for="" class="font-bold text-sm">Titular do Cartão</label>
                <InputGroup class="border rounded-lg flex-wrap">
                    <InputGroupAddon>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                        </svg>
                    </InputGroupAddon>
                    <InputText v-model="holder" class="px-4" placeholder="IGUAL AO CARTÃO"></InputText>
                </InputGroup>
            </div>

            <div class="mt-4">
                <label for="" class="font-bold text-sm">Número do Cartão</label>
                <InputGroup class="border rounded-lg flex-wrap">
                    <InputGroupAddon>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                        </svg>
                    </InputGroupAddon>
                    <InputText @keyup="cardMask" @focusout="loadCardInformations" v-model="this.number" maxlength="19" class="px-4" placeholder="0000 0000 0000 0000"></InputText>
                </InputGroup>

                <div v-if="this.checking_card">
                    <ProgressBar mode="indeterminate" class="h-2 mt-1"/>
                    <p class="text-center font-bold">Aguarde...</p>
                </div>
            </div>

            <div class="flex gap-10 mt-4">
                <div class="grow">
                    <label for="" class="font-bold text-sm">Validade do Cartão</label>
                    <InputGroup class="border rounded-lg flex-wrap">
                        <InputGroupAddon>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                            </svg>
                        </InputGroupAddon>
                        <InputMask id="" v-model="expiration" class="px-4" mask="99/99" placeholder="00/00" />
                    </InputGroup>
                </div>

                <div class="grow">
                    <label for="" class="font-bold text-sm">Código de Segurança</label>
                    <InputGroup class="border rounded-lg flex-wrap">
                        <InputGroupAddon>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                            </svg>
                        </InputGroupAddon>
                        <InputText id="" v-model="cvv" maxlength="4" class="px-4" placeholder="****" />
                    </InputGroup>
                </div>
            </div>

            <div class="mt-4" v-if="installments_options.length > 0">
                <label for="" class="font-bold text-sm">Quantidade de Parcelas</label>
                <InputGroup class="border rounded-lg">
                    <InputGroupAddon>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                        </svg>
                    </InputGroupAddon>

                    <Dropdown
                        v-model="installments"
                        :options="installments_options"
                        optionLabel="label"
                        placeholder="Selecione a Quantidade de Parcelas"
                        class="w-full md:w-14rem text-black"
                        @change="updateInstallment"
                    >
                    </Dropdown>
                </InputGroup>
            </div>
        </form>

        <p class="text-center text-lg xl:text-2xl font-bold mt-8">Total do pedido: <span class="text-blue-800">{{ getTotalPrice() }}</span></p>

        <!-- <button
            class="bg-green-600 text-white p-2 px-6 rounded-lg shadow-lg flex items-center gap-1 mx-auto mt-8"
            :class="disabled_button ? `bg-gray-300` : ''"
            :disabled="disabled_button"
            @click="pay"
        >
        </button> -->

        <Button
            class="bg-green-600 text-white p-2 px-6 rounded-lg shadow-lg flex items-center gap-1 mx-auto mt-8"
            :class="disabled_button ? `bg-gray-300` : ''"
            :disabled="disabled_button"
            @click="pay"
            :label="textButton"
            icon="pi pi-credit-card"
        ></Button>
        
        <div v-if="this.checking_card" class="absolute top-0 left-0 w-full h-full bg-white z-50 opacity-50"></div>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import InputGroup from 'primevue/inputgroup'
import InputGroupAddon from 'primevue/inputgroupaddon'
import InputMask from 'primevue/inputmask'
import Dropdown from 'primevue/dropdown'
import ProgressBar from 'primevue/progressbar'
import Button from 'primevue/button'

import paymentService from '@/services/paymentService'

export default {
    name: 'Credit Card',
    data() {
        return {
            number: null,
            expiration: null,
            cvv: null,
            holder: null,
            installments: null,
            disabled_button: true,
            checking_card: false,
            brand_credit_card: null,
            payment_token: null,
            card_mask: null,
            installments_options: [],
            valorTotal: JSON.parse(localStorage.getItem('payment')).total,
            totalPrice: null,
            textButton: 'Pagar com Cartão'
        }
    },
    methods: {
        getTotalPrice() {
            return (this.valorTotal / 100).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })
        },
        cardMask(e) {
            let number = e.target.value
            let trimmedValue = number.replace(/\s/g, '');
            let formattedValue = '';

            // General Cards
            for (let i = 0; i < trimmedValue.length; i++) {
                if (i > 0 && i % 4 === 0) {
                    formattedValue += ' ';
                }
                formattedValue += trimmedValue.charAt(i);
            }

            // Amex
            if (trimmedValue.startsWith('34') || trimmedValue.startsWith('37')) {
                formattedValue = '';
                e.target.maxLength = 17
                for (let i = 0; i < trimmedValue.length; i++) {
                    if (i === 4 || i === 10) {
                        formattedValue += ' ';
                    }
                    formattedValue += trimmedValue.charAt(i);
                }
            } else {
                e.target.maxLength = 19
            }

            this.number = formattedValue
        },
        loadCardInformations(e) {
            if (e.target.value === '') {
                return
            }

            this.checking_card = true

            if (this.number.replace(/\s/g, '').length < 15) {
                console.log(this.number.replace(/\s/g, '').length)
                this.$swal.fire('Cartão Inválido', 'Por favor, verifique o número digitado.', 'error')
                this.checking_card = false
                return
            }
            // Identificar Bandeira
            try {
                EfiJs.CreditCard
                .setCardNumber(e.target.value)
                .verifyCardBrand()
                .then(brand => {
                    console.log(brand)
                    if (brand === 'undefined') {
                        this.checking_card = false
                        return false
                    }

                    this.brand_credit_card = brand
                    this.getInstallments(brand)
                })
            } catch (error) {
                console.log(error)
                return
            }
        },
        async createPaymentToken() {
            try {
                const number = this.number.replace(/\s/g, '')

                await EfiJs.CreditCard
                    .setAccount('3539eb1ba98feea5e6f2c8070f1c26e3')
                    .setEnvironment('sandbox') // 'production' or 'sandbox'
                    .setCreditCardData({
                        brand: this.brand_credit_card,
                        number: number,
                        cvv: this.cvv,
                        expirationMonth: `${this.expiration.substring(0,2)}`,
                        expirationYear: `20${this.expiration.substring(this.expiration.length - 2)}`,
                        reuse: false
                    })
                    .getPaymentToken()
                    .then(data => {
                        this.payment_token = data.payment_token
                        this.card_mask = data.card_mask

                        const pay = paymentService.paymentCreditCard({
                            payment_token: this.payment_token,
                            installments: this.installments.installment,
                            order_hash: this.$route.params.hash,
                            cart: JSON.parse(localStorage.getItem('cart')),
                            valorTotal: this.valorTotal
                        })

                        pay.then((response) => {
                            if (response.data.data.status && response.data.data.status === 'approved') {
                                this.$swal.fire('Pagamento Realizado com Sucesso!', '', 'success')
                            } else {
                                this.$swal.fire('Seu Pagamento Está em Análise!', '', 'warning')
                            }

                            setTimeout(() => {
                                this.$router.push('/area-cliente')
                            }, 3000)
                        })
                        
                        return pay
                        
                    }).catch(err => {
                        console.log(err)
                    });
            } catch (error) {
                console.log(error)
            }

            this.textButton = `Pagar com Cartão`
        },
        getInstallments(brand) {
            try {
                EfiJs.CreditCard
                .setAccount('3539eb1ba98feea5e6f2c8070f1c26e3')
                .setEnvironment('sandbox')
                .setBrand(`${brand}`)
                .setTotal(parseInt(this.valorTotal))
                .getInstallments()
                .then(response => {
                    response.installments.forEach((element) => {
                        this.installments_options.push({
                            label: `${element.installment}x de R$ ${element.currency} ${element.has_interest ? 'com juros' : 'sem juros'}`,
                            installment: element.installment,
                            value: element.value,
                            currency: element.currency
                        })
                    })

                    this.checking_card = false
                })
            } catch (error) {
                console.log(error)
            }
        },
        installmentView() {
            console.log(this.installments)
        },
        updateInstallment() {
            this.disabled_button = false
            this.valorTotal = this.installments.value * this.installments.installment
        },
        pay() {
            this.textButton = `Aguarde...`
            this.createPaymentToken()
        }
    },
    components: {
        InputText, InputGroup, InputGroupAddon, InputMask, Dropdown, ProgressBar, Button
    }
}
</script>

<style lang="scss" scoped>

</style>