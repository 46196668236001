<template>
    <div class="edit-profile">
        <div class="flex dashboard-wrapper">
            <div class="w-[15%] bg-slate-100">
                <SidebarDashboard/>
            </div>

            <div class="w-[85%]">
                <Header></Header>

                <div class="dashboard-content p-5 px-10 pb-20 min-h-screen bg-white">
                    <h2 class="mb-4 font-bold text-3xl">{{ currentTitle }}</h2>

                    <div class="flex gap-8 items-start">
                        <div>
                            <img width="300" height="300" class="rounded-md" :src="userData.avatar" alt="">
                            <button class="p-1 px-3 bg-blue-600 text-white mt-2 rounded-lg">Alterar Foto</button>
                        </div>

                        <div class="grow bg-white p-12 px-5 rounded-md shadow-md">
                            <FloatLabel class="mb-10">
                                <InputText class="p-2 border w-full" id="name" v-model="userData.name" />
                                <label for="name">Nome</label>
                            </FloatLabel>

                            <FloatLabel class="mb-10">
                                <InputText class="p-2 border w-full" id="email" v-model="userData.email" />
                                <label for="email">E-mail</label>
                            </FloatLabel>

                            <FloatLabel>
                                <InputText class="p-2 border w-full" id="occupation" v-model="userData.occupation" />
                                <label for="occupation">Função</label>
                            </FloatLabel>
                        </div>
                    </div>

                    <Button
                        icon="pi pi-save"
                        label="Salvar Perfil"
                        class="p-2 px-4 mt-10 bg-green-500 text-white fixed right-5 bottom-5 z-50"
                        @click="saveProfile"
                    />
                </div>

                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarDashboard from '@/components/Dashboard/SidebarDashboard.vue'
import Header from '@/components/Dashboard/HeaderDashboard.vue'

import InputText from 'primevue/inputtext'
import FloatLabel from 'primevue/floatlabel'
import Button from 'primevue/button'

export default {
    name: 'Dashboard Orders',
    data() {
        return {
            currentTitle: "",
            userData: {}
        }
    },
    mounted() {
        this.currentTitle = this.$route.meta.title
        this.$watch("$route", () => {
            this.currentTitle = this.$route.meta.title;
        });

        const userData = JSON.parse(localStorage.getItem('userData'))

        this.userData = {
            avatar: userData.avatar,
            name: userData.name,
            occupation: userData.occupation,
            email: userData.email
        }
    },
    methods: {
        saveProfile() {
            alert('Saving..')
        }
    },
    components: {
        SidebarDashboard, Header, InputText, FloatLabel, Button
    }
}
</script>

<style lang="scss" scoped>

</style>