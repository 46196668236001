import { authStore } from '@/store/authStore'
import axios from 'axios'
import router from '@/router'

export default {
    async login(endpoint, credentials) {
        const store = authStore()
        
        try {
            await axios.post(endpoint, credentials).then(response => {
                
                store.actions.setToken(response.data.accessToken)

            })
        } catch(e) {
            console.log(e.response)
        }
    },
    logout() {
        localStorage.removeItem('token')
        localStorage.removeItem('expireToken')
        localStorage.removeItem('customerData')
        localStorage.removeItem('cart')
        localStorage.removeItem('cart_timestamp')
        localStorage.removeItem('order_hash')
        localStorage.removeItem('payment')
        localStorage.removeItem('paymentData')
        router.push('/')
    },
    async checkUserRole() {
        try {
            let check = await axios.get('/checkUserRole', {
                headers: {
                    'Authorization': 'Bearer '+localStorage.getItem('token')
                }
            })

            console.log(check)

            // if (!check.data.isAdmin) {
            //     router.push('/')
            // }

            // return check.data.isAdmin
        } catch(e) {
            //router.push('/')
            //return false
        }
    },
    async validateUserRole() {
        const isAdmin = await authService.checkUserRole().then((response) => {
            console.log(response)
        })

        console.log(isAdmin)

        if (!isAdmin) {
            //this.$router.push('/customer')
        }
    },
    async isAuthenticated () {
        const token = localStorage.getItem('token')

        if (!token) {
            return false
        }
        
        try {
          return await axios.get('/validate-jwt', {
            headers: {
              Authorization: 'Bearer '+token
            }
          })
        } catch(e) {
          if (e.request.status === 401) {
            localStorage.removeItem('userData')
            localStorage.removeItem('customerData')
            localStorage.removeItem('paymentData')
            localStorage.removeItem('token')
            localStorage.removeItem('expireToken')
            localStorage.removeItem('cart')
            localStorage.removeItem('payment')
            localStorage.removeItem('cart_timestamp')
            localStorage.removeItem('order_hash')

            return false
          }
        }
    }
};