import requestService from "./requestService"

export default {
    setPayment(payment) {
        localStorage.setItem('payment', JSON.stringify({
            payment_method: payment.payment_method,
            total: payment.total+'00'
        }))
    },
    async paymentCreditCard(data) {
        return await requestService.post('/customer-payment/payment-creditcard', data)
    },
    setPaymentData(data) {
        localStorage.setItem('paymentData', JSON.stringify(data))
    },
    getPaymentMethod() {
        let method = JSON.parse(localStorage.getItem('payment'))
        return method ? method.payment_method : null 
    },
    getPaymentData() {
        return JSON.parse(localStorage.getItem('paymentData'))
    },
    deletePaymentData() {
        localStorage.removeItem('paymentData')
    },
    async checkPayment(type, payment_checker) {
        return await requestService.get('/customer-payment/check-payment', {
            type: type,
            payment_checker: payment_checker
        })
    }
}