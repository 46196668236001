<template>
    <main>
        <div class="flex dashboard-wrapper">
            <div class="w-[15%] bg-white">
                <Sidebar></Sidebar>
            </div>

            <div class="w-[85%]">
                <Header></Header>

                <div class="dashboard-content p-5 px-10 pb-20 min-h-screen bg-white">
                    <h2 class="mb-4 font-bold text-3xl">{{ currentTitle }}</h2>

                    <BoxesCountDashboard :data="boxesData"></BoxesCountDashboard>

                    <div class="mt-14 flex gap-10">
                        <div class="w-full max-w-[65%]">
                            <!-- <Chart type="polarArea" :data="chartData" :options="chartOptions" class="w-full md:w-30rem" /> -->
                            <Chart type="bar" :data="barData" :options="barOptions" class="h-full" />
                        </div>

                        <div class="w-full max-w-[35%]">
                            <Chart type="pie" :data="chartData" :options="chartOptions" class="w-full md:w-30rem" />
                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </div>
        </div>
    </main>
</template>

<script>
import Header from '@/components/Dashboard/HeaderDashboard.vue'
import Footer from '@/components/Dashboard/FooterDashboard.vue'
import Sidebar from '@/components/Dashboard/SidebarDashboard.vue'
import BoxesCountDashboard from '@/components/Dashboard/BoxesCountDashboard.vue'

import { authStore } from '@/store/authStore'
import authService from '@/services/authService'
import customersService from '@/services/customersService'
import Chart from 'primevue/chart'
import initService from '@/services/initService'

const date = new Date()

export default {
    name: 'dashboard-view',
    components: {
        Header, Footer, Sidebar, BoxesCountDashboard, Chart
    },
    data() {
        return {
            currentTitle: "",
            boxesData: {
                customers: 0,
                hirings: 0,
                hirings_month: 0,
                customer_vehicles: 0,
            },
            chartData: null,
            chartDataArray: [],
            chartOptions: null,
            barOptions: null,
            barData: null
        }
    },
    methods: {
        getChartData() {
            initService.getAllNumbers().then((response) => {
                this.boxesData = response.data
                this.chartDataArray = Object.values(this.boxesData)
                this.chartData = this.setChartData();
                this.chartOptions = this.setChartOptions();
            })
        },
        setChartData() {
            const documentStyle = getComputedStyle(document.body);

            return {
                labels: [
                    'Total de Clientes',
                    'Contratatações',
                    `Contratações em ${date.getMonth()+1}/${date.getFullYear()}`,
                    'Total de Veículos'
                ],
                datasets: [
                    {
                        data: this.chartDataArray,
                        backgroundColor: [
                            documentStyle.getPropertyValue('--blue-500'),
                            documentStyle.getPropertyValue('--red-400'),
                            documentStyle.getPropertyValue('--purple-500'),
                            documentStyle.getPropertyValue('--green-500'),
                        ],
                        hoverBackgroundColor: [
                            documentStyle.getPropertyValue('--blue-700'),
                            documentStyle.getPropertyValue('--red-600'),
                            documentStyle.getPropertyValue('--purple-700'),
                            documentStyle.getPropertyValue('--green-700'),
                        ]
                    }
                ]
            };
        },
        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');

            return {
                plugins: {
                    legend: {
                        labels: {
                            usePointStyle: true,
                            color: textColor
                        }
                    }
                }
            };
        },
        setBarData() {
            const documentStyle = getComputedStyle(document.documentElement);

            return {
                labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                datasets: [
                    {
                        type: 'line',
                        label: 'Dataset 1',
                        borderColor: documentStyle.getPropertyValue('--neutral-900'),
                        borderWidth: 2,
                        fill: false,
                        tension: 0.4,
                        data: [50, 25, 12, 48, 56, 76, 42]
                    },
                    {
                        type: 'bar',
                        label: 'Dataset 2',
                        backgroundColor: documentStyle.getPropertyValue('--red-300'),
                        data: [21, 84, 24, 75, 37, 65, 34],
                        borderColor: 'white',
                        borderWidth: 2
                    },
                    {
                        type: 'bar',
                        label: 'Dataset 3',
                        backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                        data: [41, 52, 24, 74, 23, 21, 32]
                    }
                ]
            };
        },
        setBarOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

            return {
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                plugins: {
                    legend: {
                        labels: {
                            color: textColor
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            };
        }
    },
    beforeMount() {
        
    },
    beforeCreate() {
        //authService.checkUserRole()
    },
    mounted() {
        const auth = authStore()
    
        this.getChartData()
        this.barData = this.setBarData()
        this.barOptions = this.setBarOptions()

        customersService.getLocalCustomers()

        this.currentTitle = this.$route.meta.title
        this.$watch("$route", () => {
            this.currentTitle = this.$route.meta.title;
        });
    },
}
</script>

<style lang="scss">
</style>