import axios from "axios"
import storeService from "./storeService"

const store = storeService
const token = store.getToken()

export default {
    async get(url, params = {}) {
        try {
            return await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: params
            })
        } catch(e) {
            console.log(e)
        }
    },
    async post(url, params = {}) {
        try {
            return await axios.post(url, params, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
        } catch(e) {
            console.log(e)
        }
    }
}