<template>
    <div>
        <div class="card">
            <DataTable
                ref="dt"
                :value="orders"
                class="text-sm shadow"
                v-model:selection="selectedOrders"
                dataKey="customer_id"
                :paginator="true"
                :rows="25"
                :rowsPerPageOptions="[5,10,25,50,100]"
                :filters="filters"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} pedidos">

                <template #header>
                    <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
						<IconField iconPosition="left">
                            <InputIcon>
                                <i class="pi pi-search me-3" />
                            </InputIcon>

                            <InputText class="bg-white p-3 border" v-model="filters['global'].value" placeholder="Buscar Pedidos..." />
                        </IconField>
					</div>
                </template>

                <!-- <Column selectionMode="multiple" :exportable="false"></Column> -->

                <Column field="order_id" header="ID Pedido" sortable>
                    <template #body="slotProps">
                        <b>#{{ slotProps.data.order_id }}</b>
                    </template>
                </Column>

                <Column field="name" header="Cliente" sortable></Column>

                <Column field="plan_name" header="Plano" sortable></Column>

                <Column field="payment_method" header="Método de Pagamento" sortable>
                    <template #body="slotProps">
                        {{ getPaymentMethod(slotProps.data.payment_method) }}
                    </template>
                </Column>

                <Column field="customer_type" header="Status" sortable class="uppercase">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.order_status === 'waiting'" class="bg-orange-500 text-white px-3 py-1 rounded-md">Aguardando</span>
                        <span v-else-if="slotProps.data.order_status === 'new'" class="bg-blue-500 text-white px-3 py-1 rounded-md">Novo</span>
                        <span v-else class="bg-green-500 text-white px-3 py-1 rounded-md">Finalizado</span>
                    </template>
                </Column>

                <Column field="payment_total" header="Total" sortable>
                    <template #body="slotProps">
                        {{ formatCurrency(slotProps.data.payment_total) }}
                    </template>
                </Column>

                <Column field="created_at" header="Data do Pedido" sortable>
                    <template #body="slotProps">
                        {{ formatDate(slotProps.data.created_at) }}
                    </template>
                </Column>

                <!-- <Column :exportable="false" header="Ações" class="flex">
                    <template #body="slotProps">

                    </template>
                </Column> -->
            </DataTable>
        </div>
    </div>

    <Toast></Toast>
</template>

<script>
import DataTable from 'primevue/datatable'
import Button from 'primevue/button'
import Column from 'primevue/column'
import Tag from 'primevue/tag'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import RadioButton from 'primevue/radiobutton'
import ProgressBar from 'primevue/progressbar'
import SelectButton from 'primevue/selectbutton'
import Checkbox from 'primevue/checkbox'

import { FilterMatchMode } from 'primevue/api'
import customersService from '@/services/customersService'
import dateService from '@/services/dateService'
import orderService from '@/services/orderService'

export default {
    name: 'table-orders',
    data() {
        return {
            filters: {},
            submitted: false,
            selectedOrders: {},
            customers: [],
            orders: [],
        }
    },
    created() {
        this.initFilters();
    },
    methods: {
        formatCurrency(value) {
            if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'BRL'});
			return;
        },
        formatDate(date) {
            return dateService.formatBR(date)
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.customers.length; i++) {
                if (this.customers[i].id === id) {
                    index = i;
                    break;
                }
            }

            return index;
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
        getOrderStatus(status) {
            let response = ''
            switch (status) {
                case 'new':
                    response = 'Novo'
                    break
                case 'finished':
                    response = 'Finalizado'
                    break
            }
            return response
        },
        getPaymentMethod(payment_method) {
            let response = null

            switch (payment_method) {
                case 'pix':
                    response = 'Pix'
                    break
                case 'credit_card':
                    response = 'Cartão de Crédito'
                    break
                case 'ticket':
                    response = 'Boleto'
                    break
            }

            return response
        }
    },
    mounted() {
        customersService.allCustomers().then((response) => {
            this.customers = response.data
        })

        orderService.getAll().then((response) => {
            this.orders = response.data
            console.log(response.data)
        })

        console.log(this.orders)
    },
    components: {
        Button,
        DataTable,
        Column,
        Tag,
        InputText,
        Dropdown,
        Calendar,
        RadioButton,
        ProgressBar,
        SelectButton,
        Checkbox
    }
}
</script>

<style lang="scss">

</style>