<template>
    <div class="h-screen overflow-hidden login-customer bg-white lg:bg-gray-100 flex items-center justify-center relative">
        <div class="container mx-auto">
            <div class="bg-white p-4 lg:p-8 mt-4 lg:border lg:shadow-xl rounded-xl max-w-sm mx-auto relative z-50">
                <img class="mx-auto max-w-[120px]" src="@/assets/logo-colorida.png" alt="">
                <h2 class="text-md font-bold text-center my-2 text-gray-500">Área do Cliente</h2>

                <hr class="block mb-4">

                <form @submit.prevent="submitForm">
                    <div class="p-field mb-5">
                        <label for="username" class="text-sm font-bold">CPF/CNPJ</label>
                        <InputText type="text" id="email-login-customer" class="block w-full p-2 border rounded-xl" placeholder="Digite seu CPF/CNPJ" v-model="document" />
                    </div>

                    <div class="p-field mb-5">
                        <label for="password" class="text-sm font-bold">Senha</label>
                        <Password v-model="password" id="password-login-customer" :feedback="false" placeholder="*********" class="input-password block w-full p-2 border rounded-xl" toggleMask/>
                    </div>

                    <RouterLink to="/reset-password" class="text-sm font-bold text-center block text-blue-800 mb-3">Esqueceu a senha?</RouterLink>

                    <Button type="submit" class="py-2 px-5 w-full rounded-xl bg-blue-800 hover:bg-blue-900 text-white shadow-none mb-3" :disabled="await" :label="await ? `Aguarde...` :`Entrar`" />

                    <RouterLink to="/reset-password" class="font-bold text-center block border py-2 px-5 rounded-xl bg-slate-50 duration-300 border-slate-100 hover:bg-slate-200">Não Tenho Conta</RouterLink>
                </form>
            </div>

            <Toast></Toast>
            <FloatButton></FloatButton>
        </div>

        <img width="100%" class="opacity-10 object-cover absolute bottom-[-50%] left-0" src="@/assets/bg-tracking.webp" alt="">
    </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Button from 'primevue/button'
import FloatButton from '@/components/Geral/FloatButton.vue'
import axios from 'axios'
import { authStore } from '@/store/authStore'
import authService from '@/services/authService'

export default {
    name: 'Customer Login',
    data() {
        return {
            document: null,
            password: null,
            await: false
        }
    },
    methods: {
        async submitForm() {
            const store = authStore()

            if (!this.document || !this.password) {
                this.$toast.add({ severity: 'warn', summary: 'Campos Vazios!', detail: 'Por favor, preencha todos os campos..', life: 3000 });
                return false;
            }

            try {
                this.await = true
                await axios.post('/customer/login', {
                    document: this.document,
                    password: this.password
                }).then((response) => {
                    this.await = false

                    if (response.data.status !== 200) {
                        switch (response.data.status) {
                            case 401:
                                this.$toast.add({ severity: 'error', summary: 'Dados Incorretos!', detail: 'Por favor, tente novamente..', life: 3000 });
                                break
                            case 402:
                                this.$toast.add({ severity: 'warn', summary: 'Acesso não permitido!', detail: 'Você não tem permissão para acessar esta área...', life: 3000 });
                                break
                            case 403:
                                this.$toast.add({ severity: 'warn', summary: 'Acesso Inativado!', detail: 'Aguarde a liberação do acesso!', life: 3000 });
                                break
                        }
                        return false
                    }

                    store.setToken(response.data.accessToken)
                    store.setExpireToken(response.data.expires_in)
                    store.setCustomerData(response.data.customerData)
                    this.$toast.add({ severity: 'success', summary: 'Login Realizado com Sucesso!', detail: 'Aguarde... Você será redirecionado.', life: 3000 });
                    this.$router.push('/area-cliente')
                })
            } catch (error) {
                console.log(error)
            }
        },
        async isAuthenticated() {
            const request = await authService.isAuthenticated()

            if (!request) {
                return
            }

            if (request.request.status && request.request.status === 200) {
                this.$router.push('/area-cliente')
            }
        }
    },
    mounted() {
        this.isAuthenticated()
    },
    components: {
        InputText, Password, Button, FloatButton
    }
}
</script>

<style lang="scss">
#password-login-customer {
    padding: 0 !important;
}
#password-login-customer .p-inputtext {
    width: 100%;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    border-radius: 0.75rem;
}
</style>