<template>
    <div class="customer-dashboard bg-slate-50 min-h-screen">
        <div class="wrapper-customer-dashboard flex flex-wrap flex-col xl:flex-row min-h-screen items-start xl:items-stretch">
            <div class="aside w-[100%]">
                <AsideCustomerDashboard></AsideCustomerDashboard>
            </div>

            <div class="content container mx-auto py-10 xl:py-20 px-5 xl:px-12 2xl:px-4">
                <h2 class="font-bold text-3xl text-neutral-600">{{ currentTitle }}</h2>

                <hr class="block my-6">

                <div class="breadcrumbs mb-6">
                    <RouterLink to="/area-cliente">
                        <i class="pi pi-home"></i> Voltar ao Início
                    </RouterLink>

                    <span>{{ currentTitle }}</span>
                </div>

                <form @submit.prevent="updatePassword" method="POST">
                    <fieldset>
                        <label class="font-bold">Nova Senha</label>
                        <InputText class="w-full p-2 border" v-model="newPassword" />
                    </fieldset>

                    <Button type="submit" class="bg-green-500 text-white p-1 px-4 rounded-md mt-4" :disabled="!newPassword">Atualizar Senha</Button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import AsideCustomerDashboard from '@/components/Customer/AsideCustomerDashboard.vue'

import Fieldset from 'primevue/fieldset'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'

import customerConfigurationService from '@/services/customerConfigurationService'

export default {
    name: 'Customer COnfigurations',
    data() {
        return {
            currentTitle: '',
            newPassword: null
        }
    },
    components: {
        AsideCustomerDashboard, Fieldset, InputText
    },
    methods: {
        updatePassword() {
            const customer = JSON.parse(localStorage.getItem('customerData'))

            customerConfigurationService.updatePassword(customer.id, this.newPassword).then((response) => {
                if (response.status !== 200) {
                    this.$swal.fire('Erro ao atualizar Senha', '', 'error')
                    return false
                }

                this.$swal.fire('Senha Atualizada com Sucesso!', '', 'success')
                this.newPassword = null
            })
        }
    },
    mounted() {
        this.currentTitle = this.$route.meta.title
        this.$watch("$route", () => {
            this.currentTitle = this.$route.meta.title;
        })
    }
}
</script>

<style lang="scss" scoped>

</style>