<template>
    <div class="vehicles">
        <div class="flex dashboard-wrapper">
            <div class="w-[15%] bg-slate-100">
                <SidebarDashboard/>
            </div>

            <div class="w-[85%]">
                <Header></Header>

                <div class="dashboard-content p-5 px-10 pb-20 min-h-screen bg-white">
                    <h2 class="mb-4 font-bold text-3xl">{{ currentTitle }}</h2>
                    
                    <div class="flex">
                        <!-- <div class="w-[100%] flex gap-4">
                            <div class="bg-white shadow grow p-4 border rounded-lg">
                                <p class="text-lg font-bold">Contratações</p>
                                <span class="block text-5xl font-bold mt-2 text-blue-500">51</span>
                            </div>
                            <div class="bg-white shadow grow p-4 border rounded-lg">
                                <p class="text-lg font-bold">Contratações Ativas</p>
                                <span class="block text-5xl font-bold mt-2 text-blue-500">31</span>
                            </div>
                            <div class="bg-white shadow grow p-4 border rounded-lg">
                                <p class="text-lg font-bold">Contratações Inativas</p>
                                <span class="block text-5xl font-bold mt-2 text-blue-500">20</span>
                            </div>
                        </div> -->

                        <div class="w-[50%]">
                            <!-- <Chart type="pie" :data="chartData" :options="chartOptions" class="w-full md:w-30rem" /> -->
                        </div>
                    </div>

                    <TableReport class="mt-10"/>
                </div>

                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarDashboard from '@/components/Dashboard/SidebarDashboard.vue'
import Header from '@/components/Dashboard/HeaderDashboard.vue'
import initService from '@/services/initService'
import Chart from 'primevue/chart'
import TableReport from '@/components/Dashboard/TableReport.vue'

const date = new Date()

export default {
    data() {
        return {
            currentTitle: "",
            chartData: null,
            chartDataArray: [],
            chartOptions: null,
            selectedReports: [],
            reports: []
        }
    },
    methods: {
        getChartData() {
            initService.getAllNumbers().then((response) => {
                this.chartDataArray = Object.values(response.data)
                this.chartData = this.setChartData();
                this.chartOptions = this.setChartOptions();
            })
        },
        setChartData() {
            const documentStyle = getComputedStyle(document.body);

            return {
                labels: [
                    'Total de Clientes',
                    'Contratatações',
                    `Contratações em ${date.getMonth()+1}/${date.getFullYear()}`,
                    'Total de Veículos'
                ],
                datasets: [
                    {
                        data: this.chartDataArray,
                        backgroundColor: [
                            documentStyle.getPropertyValue('--blue-500'),
                            documentStyle.getPropertyValue('--red-400'),
                            documentStyle.getPropertyValue('--purple-500'),
                            documentStyle.getPropertyValue('--green-500'),
                        ],
                        hoverBackgroundColor: [
                            documentStyle.getPropertyValue('--blue-700'),
                            documentStyle.getPropertyValue('--red-600'),
                            documentStyle.getPropertyValue('--purple-700'),
                            documentStyle.getPropertyValue('--green-700'),
                        ]
                    }
                ]
            };
        },
        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');

            return {
                plugins: {
                    legend: {
                        labels: {
                            usePointStyle: true,
                            color: textColor
                        }
                    }
                }
            };
        }
    },
    mounted() {
        this.getChartData()
        this.currentTitle = this.$route.meta.title
        this.$watch("$route", () => {
            this.currentTitle = this.$route.meta.title;
        });
    },
    components: {
        SidebarDashboard, Header, Chart, TableReport
    }
}
</script>

<style lang="scss" scoped>

</style>