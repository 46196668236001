import requestService from "./requestService"

export default {
    async getAllConfigurations() {
        return await requestService.get('/configurations/all-configs')
    },
    async getVehicleTypes() {
        return await requestService.get('/configurations/vehicle-types')
    },
    async updateConfigs(data) {
        return await requestService.post('/configurations/update-configs', data)
    }
}