<template>
    <div class="customer-dashboard bg-slate-50 min-h-screen">
        <div class="wrapper-customer-dashboard lg:flex min-h-screen">
            <div class="aside">
                <AsideCustomerDashboard></AsideCustomerDashboard>
            </div>

            <div class="content container mx-auto py-10 xl:py-20 px-5 2xl:px-4">
                <h2 class="font-bold text-3xl text-neutral-600 text-center">Pagamento</h2>

                <hr class="block my-6">

                <div v-if="paymentMethod === 'pix'">
                    <Pix></Pix>
                </div>

                <div v-if="paymentMethod === 'credit_card'">
                    <CreditCard/>
                </div>

                <div v-if="paymentMethod === 'ticket'">
                    <Ticket/>
                </div>

                <RouterLink
                    to="/area-cliente/confirmacao"
                    id="button-next"
                    :class="this.$route.meta.hideBack ? `hidden` : ``"
                    class="bg-red-600 hover:bg-red-700 duration-200 text-white p-2 px-5 rounded-lg font-bold"
                >
                    <i class="fa-solid fa-arrow-left"></i> Voltar
                </RouterLink>

                <div class="flex flex-col xl:flex-row items-center justify-center gap-5 mt-14">
                    <button
                        v-if="paymentMethod !== 'credit_card'"
                        @click="cancelOrder"
                        class="bg-red-700 text-white p-2 px-6 rounded-lg shadow-lg flex items-center gap-1"
                    >Cancelar Pedido <i class="fa-solid fa-xmark"></i></button>

                    <RouterLink
                        v-if="paymentMethod !== 'credit_card'"
                        to="/area-cliente"
                        class="bg-green-700 text-white p-2 px-6 inline-block rounded-lg shadow-lg flex items-center gap-1"
                    >Já Realizei o Pagamento <i class="fa-solid fa-check"></i></RouterLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AsideCustomerDashboard from '@/components/Customer/AsideCustomerDashboard.vue'
import paymentService from '@/services/paymentService'
import cartService from '@/services/cartService'
import authService from '@/services/authService'
import orderService from '@/services/orderService'

import Pix from '@/components/Payments/Pix'
import CreditCard from '@/components/Payments/CreditCard'
import Ticket from '@/components/Payments/Ticket'

export default {
    name: 'Payment Order',
    data() {
        return {
            paymentMethod: paymentService.getPaymentMethod()
        }
    },
    components: {
        AsideCustomerDashboard, CreditCard, Ticket, Pix
    },
    methods: {
        async cancelOrder() {
            const order_hash = this.$route.params.hash
            const request = await orderService.cancelOrder(order_hash)
            
            if (request.data.status === 200) {
                this.$router.push('/area-cliente')
            }
        },
        checkValidateOrder() {
            orderService.checkOrder(this.$route.params.hash).then((response) => {
                if (response.data.order_status === 'finished') {
                    this.$router.push('/area-cliente')
                    return false
                }
            })
        }
    },
    beforeCreate() {
        const auth = authService
        auth.isAuthenticated()
    },
    mounted() {
        this.checkValidateOrder()

        if (cartService.validateCartTimestamp()) {
            this.$router.push('/area-cliente')
            cartService.emptyCart()
            cartService.deleteCartTimestamp()
            localStorage.removeItem('payment_method')
            //localStorage.removeItem('order_hash')
            return false
        }
    }
}
</script>

<style lang="scss" scoped>

</style>