<template>
    <div class="customers">
        <div class="flex dashboard-wrapper">
            <div class="w-[15%] bg-white">
                <SidebarDashboard/>
            </div>

            <div class="w-[85%]">
                <Header></Header>

                <div class="dashboard-content p-5 px-10 pb-20 min-h-screen bg-white">
                    <h2 class="mb-4 font-bold text-3xl">{{ currentTitle }}</h2>

                    <TableCustomersDashboard></TableCustomersDashboard>
                </div>

                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarDashboard from '@/components/Dashboard/SidebarDashboard.vue'
import TableCustomersDashboard from '@/components/Dashboard/TableCustomersDashboard.vue'
import Header from '@/components/Dashboard/HeaderDashboard.vue'

export default {
    data() {
        return {
            currentTitle: "",
        }
    },
    methods: {
        
    },
    mounted() {
        this.currentTitle = this.$route.meta.title
        this.$watch("$route", () => {
            this.currentTitle = this.$route.meta.title;
        });
    },
    components: { SidebarDashboard, Header, TableCustomersDashboard }
}
</script>

<style lang="scss" scoped>

</style>