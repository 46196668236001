import requestService from "./requestService"

export default {
    async getAll() {
        return await requestService.get('/contracts-models/all')
    },
    async createContractModel(data) {
        return await requestService.post('/contracts-models/new', data)
    },
    async getContractModel(model_id) {
        return await requestService.get('/contracts-models/get-model', {
            model_id: model_id
        })
    },
    async updateContractModel(model_id, save_data) {
        return await requestService.post('/contracts-models/update', {
            model_id: model_id,
            data_update: save_data
        })
    }
}