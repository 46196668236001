<template>
    <div id="floating-button-support">
        <div class="floating-button-icon">
            <a href="#"><img src="@/assets/support-help.svg" alt=""></a>
        </div>

        <div class="floating-button-text bg-blue-800"><a class="text-white" href="#">Precisa de Ajuda?</a></div>
    </div>
</template>

<script>
    export default {
        name: 'Floating Button',
        data() {
            return {
                
            }
        },
    }
</script>

<style lang="scss" scoped>
#floating-button-support {
    position: fixed;
    bottom: 25px;
    right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    @media (max-width: 767px) {
        display: none;
    }
    .floating-button-icon {
        img {
            width: 35px;
            height: 35px;
            margin-bottom: 8px;
            object-fit: cover;
            object-position: center center;
        }
    }
    .floating-button-text {
        color: #ffffff;
        text-align: center;
        font-size: 0.75rem;
        line-height: 1.1;
        font-weight: bold;
        border-radius: 8px;
        padding: 5px 8px;
        box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.08);
        max-width: 100px;
    }
}
</style>