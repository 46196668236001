<template>
    <div class="vehicles">
        <div class="flex dashboard-wrapper">
            <div class="w-[15%] bg-slate-100">
                <SidebarDashboard/>
            </div>

            <div class="w-[85%]">
                <HeaderDash/>

                <div class="dashboard-content p-5 px-10 pb-20 min-h-screen bg-white">
                    <h2 class="mb-4 font-bold text-3xl">{{ currentTitle }}</h2>

                    <RouterLink to="/painel/modelos-contratos" class="text-blue-600 text-sm inline-flex items-center gap-2">
                        <i class="pi pi-arrow-left block text-sm"></i> Voltar Para os Modelos
                    </RouterLink>

                    <label class="font-bold block mt-10">Nome do Modelo</label>
                    <InputText v-model="contract_name" class="p-3 w-full border" placeholder="Ex: Modelo de Contrato Pré-pago"></InputText>

                    <label for="contract_type" class="font-bold block mt-5">Tipo de Contrato</label>
                    <Dropdown
                        id="contract_type"
                        v-model="contract_type"
                        :options="contract_types"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Selecione o Tipo de Contrato"
                        class="mt-2 w-full max-w-lg border"
                    />

                    <label for="contract_type" class="font-bold block mt-5">Conteúdo do Contrato</label>
                    <div class="mt-2 border" id="editorjs"></div>

                    <Button
                        icon="pi pi-save"
                        label="Salvar Modelo"
                        class="p-2 px-4 mt-10 bg-green-500 text-white fixed right-5 bottom-5 z-50"
                        @click="saveEditor"
                    />
                </div>

                <Footer></Footer>
            </div>
        </div>

        <Toast/>
    </div>
</template>

<script>
import SidebarDashboard from '@/components/Dashboard/SidebarDashboard.vue'
import HeaderDash from '@/components/Dashboard/HeaderDashboard.vue'

import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'

import EditorJs from '@editorjs/editorjs'
import Paragraph from '@editorjs/paragraph'
import Header from '@editorjs/header'
import NestedList from '@editorjs/nested-list'
import Table from '@editorjs/table'
import Delimiter from '@editorjs/delimiter'
import TextVariantTune from '@editorjs/text-variant-tune'
import Underline from '@editorjs/underline'

const AlignmentTuneTool = require('editorjs-text-alignment-blocktune')
const ColorPlugin = require('editorjs-text-color-plugin')
const SimpleImageUpdated = require('editorjs-simple-image-updated')
const FontSize = require('editorjs-inline-font-size-tool')

import contractsModelsService from '@/services/contractsModelsService'

export default {
    name: 'Dashboard New Contract Model',
    data() {
        return {
            currentTitle: '',
            contract_type: null,
            contract_name: null,
            contract_types: [
                {label: 'Contrato Pré-pago', value: 'contract_prepaid'},
                {label: 'Contrato Pós-pago', value: 'contract_postpaid'},
                {label: 'Termo de Contratação', value: 'hiring_term'},
                {label: 'Termo de Bloqueio', value: 'block_term'},
                {label: 'Termo de Instalação', value: 'installation_term'}
            ],
            instanceEditor: null,
        }
    },
    methods: {
        renderEditor() {
            const render = new EditorJs({
                holderId: 'editorjs',
                tools: {
                    paragraph: {
                        class: Paragraph,
                        inlineToolbar: true,
                        tunes: ['alignment', 'textVariant'],
                    },
                    heading: {
                        class: Header,
                        inlineToolbar: true,
                        tunes: ['alignment'],
                    },
                    image: {
                        class: SimpleImageUpdated,
                        tunes: ['alignment'],
                    },
                    list: {
                        class: NestedList,
                        inlineToolbar: true,
                        config: {
                            defaultStyle: 'unordered'
                        },
                        tunes: ['alignment'],
                    },
                    table: {
                        class: Table,
                        inlineToolbar: true,
                        config: {
                            rows: 2,
                            cols: 1
                        },
                        tunes: ['alignment']
                    },
                    alignment: {
                        class: AlignmentTuneTool,
                        config:{
                            default: "left",
                            blocks: {
                                header: 'left',
                                list: 'left',
                                paragraph: 'left',
                                image: 'left'
                            }
                        },
                    },
                    textVariant: {
                        class: TextVariantTune
                    },
                    underline: {
                        class: Underline
                    },
                    Color: {
                        class: ColorPlugin,
                        config: {
                            //colorCollections: ['#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF'],
                            defaultColor: '#2563eb',
                            type: 'text', 
                            customPicker: true
                        }
                    },
                    fontSize: {
                        class: FontSize
                    },
                    delimiter: {
                        class: Delimiter
                    }
                }
            })

            this.instanceEditor = render
        },
        saveEditor() {
            this.instanceEditor.save().then((saveData) => {
                if (!this.contract_type) {
                    this.$swal.fire('Selecione o Tipo de Contrato', 'Para salvar o contrato é preciso selecionado o tipo de contrato a ser salvo.', 'warning')
                    return
                }

                if (saveData.blocks.length < 1) {
                    this.$swal.fire('Documento Vazio', 'Não é possível salvar um documento em branco, insira algum conteúdo.', 'warning')
                    return
                }

                saveData.blocks.forEach((block) => {
                    if (block.data.text) {
                        block.data.text = block.data.text.replace(/\\n/g, '<br>');
                    }
                });

                try {
                    contractsModelsService.createContractModel({
                        contract_type: this.contract_type,
                        contract_name: this.contract_name,
                        saveData: saveData
                    }).then((response) => {
                        console.log(response)
                        if (!response.data || response.data.status === 500 || response.data.status === 400) {
                            this.$toast.add({
                                severity: 'error',
                                summary: 'Erro!',
                                detail: 'Não foi possível criar o modelo de contrato.',
                                life: 3000
                            });
                            return
                        }

                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sucesso!',
                            detail: 'O modelo de contrato foi atualizado com sucesso!',
                            life: 3000
                        });

                        this.$router.push(`/painel/modelos-contratos/edit/${response.data.contract_model_id}`)
                    })
                } catch(e) {
                    console.log(e)
                }

            }).catch((error) => {
                console.log(error)
            })
        }
    },
    mounted() {
        this.currentTitle = this.$route.meta.title
        this.$watch("$route", () => {
            this.currentTitle = this.$route.meta.title;
        })

        this.renderEditor()
    },
    components: {
        Dropdown, SidebarDashboard, HeaderDash, Button, InputText
    }
}
</script>

<style lang="scss" scoped>
#editorjs {
    background: #ffffff;
}
</style>