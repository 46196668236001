<template>
    <header class="bg-neutral-100 app-header border-b border-slate-100">
        <div class="flex items-center justify-between gap-5 py-4 p-10">
            <div class="flex-grow">
                <p class="m-0 text-lg">Olá, seja bem-vindoª <b class="text-blue-600">{{ userData.name }}</b></p>
            </div>

            <div class="flex-grow text-end">
                <Button @click="logout" icon="fa-solid fa-right-from-bracket" label="Sair" class="bg-red-600 hover:bg-red-800 text-white py-1 px-5"></Button>
                <ConfirmDialog group="headless">
                    <template #container="{ message, acceptCallback, rejectCallback }">
                        <div class="p-10 surface-overlay bg-white rounded-lg">
                            <span class="font-bold text-center text-2xl block mb-2 mt-4">{{ message.header }}</span>

                            <p class="mb-0 text-center">{{ message.message }}</p>

                            <div class="flex justify-center gap-4 mt-4">
                                <Button label="Confirmar" @click="acceptCallback" class="px-4 p-2 bg-green-400 w-8rem text-white"></Button>
                                <Button label="Cancelar" outlined @click="rejectCallback" class="px-4 p-2 text-white bg-red-400 w-8rem"></Button>
                            </div>
                        </div>
                    </template>
                </ConfirmDialog>
            </div>

            <div>
                <RouterLink to="/painel/editar-perfil" class="text-center flex flex-col items-center">
                    <img :src="userData.avatar" class="rounded-full" width="50" height="50" alt="">
                    <span class="text-[0.7rem] font-bold">Editar Perfil</span>
                </RouterLink>
            </div>
        </div>
    </header>
</template>

<script>
import Button from 'primevue/button'

import { authStore } from '@/store/authStore'

export default {
    name: 'header-component',
    components: {
        Button
    },
    data() {
        return {
            userData: {}
        }
    },
    methods: {
        logout() {
            this.$confirm.require({
                group: 'headless',
                header: 'Deseja sair?',
                message: 'Por favor, confirme para prosseguir..',
                accept: () => {
                    this.$toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
                    const store = authStore()
                    store.removeExpireToken()
                    store.removeToken()
                    store.removeUserData()
                    store.removeCustomers()
                    this.$router.push('/painel/login')
                },
                reject: () => {
                    this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                }
            });
        },
    },
    mounted() {
        const auth = authStore()
        this.userData = auth.getUserData
    }
}
</script>

<style lang="scss">
</style>