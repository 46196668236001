<template>
    <div class="bg-blue-800 border-red-500 py-4 xl:py-8 px-12 xl:px-4 h-full text-center">
        <RouterLink to="/area-cliente">
            <img class="max-w-[80px] mx-auto main-logo" src="@/assets/logo-branca.png" alt="">
        </RouterLink>

        <Button @click="logout" title="Sair" class="mt-4 xl:mt-8 text-white font-bold border border-slate-50 w-full py-1 px-2 flex justify-center hover:bg-blue-900 hover:border-blue-900">
            SAIR
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
            </svg>
        </Button>
    </div>
</template>

<script>
import Button from 'primevue/button'
import authService from '@/services/authService'

const auth = authService

export default {
    name: 'Aside Customer Dashboard',
    components: {
        Button
    },
    methods: {
        logout() {
            auth.logout()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>