<template>
    <div class="customer-dashboard bg-slate-50 min-h-screen">
        <div class="wrapper-customer-dashboard flex flex-wrap min-h-screen">
            <div class="aside w-[100%]">
                <AsideCustomerDashboard></AsideCustomerDashboard>
            </div>

            <div class="content container mx-auto py-10 xl:py-20 px-5 xl:px-12 2xl:px-4">
                <h2 class="font-bold text-3xl text-neutral-600">Olá, Marcos!</h2>

                <hr class="block my-6">

                <div class="flex flex-wrap gap-10">
                    <div class="grow w-[50%] w-xl-[100%]">
                        <div class="pb-6">
                            <div class="flex my-5 gap-5">
                                <!-- <BoxesCustomerDashboard
                                    :icon="iconOrders"
                                    title="Meus Pedidos"
                                /> -->

                                <BoxesCustomerDashboard
                                    :icon="agreement"
                                    title="Meus Contratos"
                                    link="/area-cliente/meus-contratos"
                                />

                                <BoxesCustomerDashboard
                                    :icon="accountConfig"
                                    title="Configurações"
                                    link="/area-cliente/configuracoes"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="grow w-[50%] w-xl-[100%]">
                        <p class="font-bold text-xl text-neutral-600">Meus Veículos</p>

                        <div class="p-5 2xl:p-10 bg-white rounded-lg shadow mt-5">
                            <HiringsCustomerDashboard/>
                        </div>

                        <div class="mt-10 text-center wrapper-buttons">
                            <div class="flex flex-col lg:flex-row items-center gap-3 justify-center">
                                <button @click="clearSelecteds" class="w-full lg:w-auto bg-red-600 hover:bg-red-700 duration-200 text-white p-2 px-5 rounded-lg font-bold lg:me-4"><i class="fa-solid fa-broom"></i> Limpar Seleção</button>
                                <RouterLink @click="setCartTimestamp" to="/area-cliente/confirmacao" id="button-next" class="w-full lg:w-auto bg-blue-800 hover:bg-blue-900 duration-200 text-white p-2 px-5 rounded-lg font-bold">Continuar Contratação <i class="fa-solid fa-arrow-right"></i></RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <FloatButton/>
    </div>
</template>

<script>
import $ from 'jquery'
import BoxesCustomerDashboard from '@/components/Customer/BoxesCustomerDashboard.vue'
import AsideCustomerDashboard from '@/components/Customer/AsideCustomerDashboard.vue'
import HiringsCustomerDashboard from '@/components/Customer/HiringsCustomerDashboard.vue'
import cartService from '@/services/cartService'
import authService from '@/services/authService'
import orderService from '@/services/orderService'

import iconTrack from '@/assets/track.png'
import iconOrders from '@/assets/check-out.png'
import vechicles from '@/assets/autonomous-car.png'
import accountConfig from '@/assets/user.png'
import agreement from '@/assets/agreement.png'

import FloatButton from '@/components/Geral/FloatButton.vue'

export default {
    name: 'Customer Dashboard',
    data() {
        return {
            iconTrack: iconTrack,
            iconOrders: iconOrders,
            vechicles: vechicles,
            accountConfig: accountConfig,
            agreement: agreement
        }
    },
    methods: {
        clearSelecteds() {
            const all = document.querySelectorAll('.plan')
            const default_value = 0.00
            const total_order = default_value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
            })

            all.forEach((element, index) => {
                element.classList.remove('selected')
            })

            $('.wrapper-buttons').fadeOut(100)
            $('.total-order b').text(total_order)
            $('.total-order').hide()
        },
        setCartTimestamp() {
            cartService.setTimestampCart()
        },
        async isAuthenticated() {
            const request = await authService.isAuthenticated()
        
            if (!request) {
                this.$router.push('/')
                return
            }

            if (request.request.status && request.request.status === 200) {
                this.$router.push('/area-cliente')
            }
        },
    },
    mounted() {
        this.isAuthenticated()
        orderService.resetOrder()
    },
    components: {
        BoxesCustomerDashboard,
        AsideCustomerDashboard,
        HiringsCustomerDashboard,
        FloatButton
    }
}
</script>

<style lang="scss" scoped>

</style>