<template>
    <div class="vehicles">
        <div class="flex dashboard-wrapper">
            <div class="w-[15%] bg-slate-100">
                <SidebarDashboard/>
            </div>

            <div class="w-[85%]">
                <Header></Header>

                <div class="dashboard-content p-5 px-10 pb-20 min-h-screen bg-white">
                    <h2 class="mb-4 font-bold text-3xl">{{ currentTitle }}</h2>

                    <div>
                        <div class="card flex justify-content-center mb-5">
                            <Dropdown
                                v-model="vehicle.customer_id"
                                :options="customers" filter
                                optionLabel="name"
                                optionValue="customer_id"
                                placeholder="Selecione um Cliente"
                                class="w-full md:w-14rem select_customer"
                            />
                        </div>

                        <div v-if="vehicle.customer_id">
                            <div class="flex flex-wrap gap-3">
                                <div class="field mb-3 grow">
                                    <label class="font-bold">Modelo</label>
                                    <InputText
                                    placeholder="Modelo do veículo"
                                    class="p-3 w-full border bg-white"
                                    v-model="vehicle.model"
                                    />
                                </div>

                                <div class="field mb-3">
                                    <label class="font-bold">Ano</label>
                                    <InputText
                                    placeholder="Ano do veículo"
                                    class="p-3 w-full border bg-white"
                                    v-model="vehicle.year"
                                    />
                                </div>

                                <div class="field mb-3">
                                    <label class="font-bold">Placa</label>
                                    <InputText
                                    placeholder="Placa do veículo"
                                    class="p-3 w-full border bg-white"
                                    v-model="vehicle.plate"
                                    />
                                </div>

                                <div class="field mb-3">
                                    <label class="font-bold">Cor</label>
                                    <InputText
                                    placeholder="Cor do veículo"
                                    class="p-3 w-full border bg-white"
                                    v-model="vehicle.color"
                                    />
                                </div>

                                <div class="field mb-3">
                                    <label class="font-bold">RENAVAM</label>
                                    <InputText
                                    placeholder="RENAVAM do veículo"
                                    class="p-3 w-full border bg-white"
                                    v-model="vehicle.renavam"
                                    />
                                </div>

                                <div class="field mb-3 grow">
                                    <label class="font-bold">Tipo de Veículo</label>
                                    <Dropdown
                                        v-model="vehicle.vehicle_type"
                                        :options="vehicleTypes"
                                        placeholder="Selecione o Tipo"
                                        class="w-full border bg-white"
                                    />
                                </div>

                                <div class="field mb-3 grow">
                                    <label class="font-bold">Marca do Veículo</label>
                                    <Dropdown
                                        v-model="vehicle.brand"
                                        :options="vehicleBrands"
                                        placeholder="Selecione a Marca"
                                        class="w-full border bg-white"
                                    />
                                </div>

                                <!-- Taxa do Equipamento -->
                                <div class="field mb-3 grow w-full">
                                    <label class="font-bold block">Taxa do Equipamento</label>
                                    <InputText v-model="vehicle.equipment_rate" placeholder="00.00" class="p-3 w-full border bg-white"></InputText>

                                    <div class="flex gap-2 items-center mt-3">
                                        <Checkbox v-model="vehicle.active_equipment_rate" inputId="active-equipment-rate" :binary="true" />
                                        <label for="active-equipment-rate" class="text-sm">Ativar Taxa do Equipamento</label>
                                    </div>
                                </div>

                                <!-- Taxa de Ativação -->
                                <div class="field mb-3 grow w-full">
                                    <label class="font-bold block">Taxa de Ativação</label>
                                    <InputText v-model="vehicle.activation_rate" placeholder="00.00" class="p-3 w-full border bg-white"></InputText>

                                    <div class="flex gap-3 items-center">
                                        <div class="flex gap-2 items-center mt-3">
                                            <Checkbox v-model="vehicle.active_activation_rate" inputId="active-activation-rate" :binary="true" />
                                            <label for="active-activation-rate" class="text-sm">Ativar Taxa de Ativação</label>
                                        </div>

                                        <div class="flex gap-2 items-center mt-3">
                                            <Checkbox inputId="automatic-activation-rate" v-model="vehicle.automatic_activation_rate" :binary="true" />
                                            <label for="automatic-activation-rate" class="text-sm">Ativar Taxa Automaticamente</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="field">
                                    <label class="font-bold">Marca do Equipamento</label>
                                    <InputText v-model="vehicle.equipment_brand" class="p-3 w-full border bg-white" placeholder="Insira o nome da marca"></InputText>
                                </div> 

                                <div class="field">
                                    <label class="font-bold">Modelo do Equipamento</label>
                                    <InputText v-model="vehicle.equipment_model" class="p-3 w-full border bg-white" placeholder="Insira o nome do equipamento"></InputText>
                                </div>

                                <div class="field">
                                    <label class="font-bold">IMEI</label>
                                    <InputText v-model="vehicle.imei" class="p-3 w-full border bg-white" placeholder="IMEI do dispositivo"></InputText>
                                </div>

                                <!-- <div class="field">
                                    <label class="font-bold">Recepção de Sinal do Equipamento</label>
                                    <InputText v-model="vehicle.signal_reception" class="p-3 w-full" placeholder="Recepção de sinal do equipamento"></InputText>
                                </div> -->

                                <div class="field">
                                    <label class="font-bold">Garantia do Equipamento</label>
                                    <InputText v-model="vehicle.equipment_warranty" class="p-3 w-full border bg-white" placeholder="Garantia do equipamento"></InputText>
                                </div>

                                <div class="flex gap-3">
                                    <div class="field">
                                        <label class="font-bold">SIM CARD</label>
                                        <InputText v-model="vehicle.sim_card" class="p-3 w-full border bg-white" placeholder="Sim Card do equipamento"></InputText>
                                    </div>

                                    <div class="field">
                                        <label class="font-bold">Operadora de Telefonia</label>
                                        <InputText v-model="vehicle.telephone_operator" class="p-3 w-full border bg-white" placeholder="operado do equipamento"></InputText>
                                    </div>

                                    <!-- <div class="field">
                                        <label class="font-bold">Frequência de Transmissão de Sinal da Operadora</label>
                                        <InputText v-model="vehicle.transmission_frequency" class="p-3 w-full" placeholder="Transmisão do equipamento"></InputText>
                                    </div> -->
                                </div>
                            </div>

                            <Button class="mt-5 p-2 text-white bg-green-500" label="Salvar Veículo" @click="saveVehicle" icon="pi pi-check" text />
                        </div>
                    </div>                    
                </div>

                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarDashboard from '@/components/Dashboard/SidebarDashboard.vue'
import Header from '@/components/Dashboard/HeaderDashboard.vue'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'

import customersService from '@/services/customersService'
import vehiclesService from '@/services/vehiclesService'
import configurationsService from '@/services/configurationsService'

export default {
    name: 'Dashboard Vehicles',
    data() {
        return {
            currentTitle: "",
            customers: [],
            vehicleBrands: [],
            vehicleTypes: [],
            vehicle: this.initialStateVehicle()
        }
    },
    methods: {
        initialStateVehicle() {
            return {
                customer_id: null,
                model: null,
                year: null,
                plate: null,
                color: null,
                renavam: null,
                vehicle_type: null,
                brand: null,
                equipment_brand: null,
                equipment_model: null,
                equipment_warranty: null,
                imei: null,
                //signal_reception: null,
                sim_card: null,
                telephone_operator: null,
                //transmission_frequency: null,
                equipment_rate: null,
                active_equipment_rate: null,
                activation_rate: null,
                active_activation_rate: null,
                automatic_activation_rate: null
            }
        },
        resetFormOnSave () {
            const keys = Object.keys(this.vehicle)
            keys.map((key) => {
                if (key != "customer_id") {
                    this.vehicle[key] = null
                }
            })
        },
        saveVehicle() {
            const vehicle = this.vehicle
            delete vehicle.active_equipment_rate
            delete vehicle.automatic_activation_rate
            delete vehicle.active_activation_rate

            if (Object.values(vehicle).includes(null)) {
                this.$swal.fire('Alguns Campos não foram preenchidos', '', 'warning')
                return
            }

            const save = vehiclesService.newVehicle(this.vehicle)

            save.then((response) => {
                if (response.status != 200) {
                    this.$swal.fire('Erro ao criar novo veículo', '', 'error')
                    return
                }

                switch (response.data.status) {
                    case 200:
                        this.$swal.fire('Veículo criado com Sucesso!', '', 'success')
                        this.resetFormOnSave()
                        break;
                    case 400:
                        this.$swal.fire('Ocorreu algum problema ao criar o veículo!', '', 'warning')
                        break;
                    default:
                        this.$swal.fire('Ocorreu algum problema ao criar o veículo!', '', 'warning')
                }
            })
        }
    },
    async mounted() {
        this.currentTitle = this.$route.meta.title
        this.$watch("$route", () => {
            this.currentTitle = this.$route.meta.title;
        });

        const customers = await customersService.getLocalCustomers()

        configurationsService.getAllConfigurations().then((response) => {            
            response.data.map((index) => {
                switch (index.configuration_key) {
                    case 'vehicle_brands':
                        this.vehicleBrands = JSON.parse(index.configuration_value)
                        break
                    case 'vehicle_types':
                        this.vehicleTypes = JSON.parse(index.configuration_value)
                        break
                }
            })
        })

        this.customers = customers
    },
    components: {
        SidebarDashboard, Header, InputText, Dropdown, Checkbox, Button
    }
}
</script>

<style lang="scss" scoped>
</style>