<template>
    <div class="customer-dashboard bg-slate-50 min-h-screen">
        <div class="wrapper-customer-dashboard flex flex-wrap min-h-screen">
            <div class="aside w-[100%]">
                <AsideCustomerDashboard></AsideCustomerDashboard>
            </div>

            <div class="content container mx-auto py-10 xl:py-20 px-5 xl:px-12 2xl:px-4">
                <h2 class="font-bold text-3xl text-neutral-600">{{ currentTitle }}</h2>

                <hr class="block my-6">

                <div class="breadcrumbs mb-6">
                    <RouterLink to="/area-cliente">
                        <i class="pi pi-home"></i> Voltar ao Início
                    </RouterLink>

                    <span>{{ currentTitle }}</span>
                </div>

                <div class="shadow-md p-4 rounded-lg bg-white my-2 border" v-for="contract in contracts">
                    <p><b>Número do Contrato:</b> {{ contract.contract_number }}</p>
                    <p><b>Tipo de Contato:</b> {{ contract.contract_type }}</p>
                    <p><b>Criado em:</b> {{ createdDate(contract.creation_date) }}</p>
                    <p><b>Status:</b> {{ contract.signed ? 'Assinado' : 'Não Assinado' }}</p>
                    <p v-if="!contract.signed"><b>Obs:</b> <span class="text-sm text-red-400">Para assinar este contrato acesse seu email cadastrado no sistema.</span></p>
                    <a v-if="contract.contract_link" href="#" target="_blank"
                    class="mt-2 p-1 px-5 bg-blue-600 text-white text-sm font-bold rounded-md inline-flex items-center gap-1 duration-200 hover:bg-blue-800">
                        <i class="pi pi-file-pdf"></i> Ver Contrato
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AsideCustomerDashboard from '@/components/Customer/AsideCustomerDashboard.vue'

import contractsService from '@/services/contractsService'
import dateService from '@/services/dateService'

export default {
    name: 'Customer Contracts',
    data() {
        return {
            currentTitle: '',
            contracts: null
        }
    },
    components: {
        AsideCustomerDashboard
    },
    methods: {
        async all() {
            const customer_id = JSON.parse(localStorage.getItem('customerData'))
            const all = await contractsService.getAll(customer_id.id)
            this.contracts = all.data.contracts
        },
        createdDate(date) {
            return dateService.formatBR(date)
        }
    },
    mounted() {
        this.all()

        this.currentTitle = this.$route.meta.title
        this.$watch("$route", () => {
            this.currentTitle = this.$route.meta.title;
        })
    }
}
</script>

<style lang="scss" scoped>

</style>
