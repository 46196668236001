<template>
    <div class="vehicles">
        <div class="flex dashboard-wrapper">
            <div class="w-[15%] bg-slate-100">
                <SidebarDashboard/>
            </div>

            <div class="w-[85%]">
                <Header></Header>

                <div class="dashboard-content p-5 px-10 pb-20 min-h-screen bg-white">
                    <h2 class="mb-4 font-bold text-3xl">{{ currentTitle }}</h2>

                    <!-- Planos -->
                    <Fieldset class="mb-8" legend="Planos" :toggleable="true">
                        <div class="w-full flex gap-3 mb-3" v-for="(p, index) in this.plans" :key="p">
                            <div class="grow w-[600px]">
                                <label class="font-bold text-xs">Nome do Plano</label>
                                <InputText v-model="plans[index].plan_name" class="w-full p-2 border" placeholder="Nome do Plano" />
                            </div>

                            <div class="">
                                <label class="font-bold text-xs">Valor do Plano</label>
                                <InputText v-model="plans[index].plan_price" class="w-full p-2 border" placeholder="Valor de Plano Ex: 50.00" />
                            </div>

                            <div class="">
                                <label class="font-bold text-xs">Duração em Meses</label>
                                <InputText v-model="plans[index].plan_months_period" class="w-full p-2 border" placeholder="Total de Meses" />
                            </div>

                            <div class="">
                                <label class="font-bold text-xs">Duração em Dias</label>
                                <InputText v-model="plans[index].plan_days_period" class="w-full p-2 border" placeholder="Total de Dias" />
                            </div>

                            <div v-if="!p.plan_status" class="self-end text-center cursor-pointer" @click="changeStatusPlan(p)">
                                <label class="font-bold text-xs block">Inativo</label>
                                <i class="fa-solid fa-ban text-red-400"></i>
                            </div>

                            <div v-else class="self-end text-center cursor-pointer" @click="changeStatusPlan(p)">
                                <label class="font-bold text-xs block">Ativo</label>
                                <i class="fa-regular fa-circle-check text-green-400"></i>
                            </div>
                        </div>

                        <button @click="addNewPlan" class="p-1 px-2 bg-green-600 text-white text-sm rounded">Novo Plano <i class="text-xs pi pi-plus"></i></button>                        
                    </Fieldset>

                    <!-- Período de Carência -->
                    <Fieldset class="mb-8">
                        <div>
                            <label class="font-bold block">Período de Carência da Taxa de Ativação</label>
                            <InputGroup class="border rounded-lg flex flex-wrap max-w-[200px]">
                                <InputNumber mode="decimal" showButtons class="p-2 ps-5 pe-0" v-model="configs.payment_grace_period" placeholder="Ex: 30" />
                                <InputGroupAddon><b>Dias</b></InputGroupAddon>
                            </InputGroup>
                        </div>
                    </Fieldset>

                    <!-- Veículos -->
                    <Fieldset class="mb-8" legend="Veículos" :toggleable="true">
                        <div class="w-full flex flex-col gap-8">
                            <div class="grow">
                                <label class="font-bold block">Tipos de Veículos</label>
                                <Chips v-model="configs.vehicle_types" class="chips vehicle_types w-full border rounded-[6px]" variant="filled" placeholder="Digite o Tipo de Veículo" />
                            </div>

                            <div class="grow">
                                <label class="font-bold block">Marcas</label>
                                <Chips v-model="configs.vehicle_brands" class="chips vehicle_brands w-full border rounded-[6px]" variant="filled" placeholder="Digite a Marca de Veículo" />
                            </div>
                        </div>
                    </Fieldset>

                    <!-- Pagamentos -->
                    <Fieldset legend="Pagamento/API" :toggleable="true">
                        <div class="w-full flex flex-col gap-8">
                            <div class="grow">
                                <label class="font-bold block">Chave Pix</label>
                                <InputText v-model="configs.pix_key" class="w-full p-2 border" placeholder="Insira a Chave Pix" />
                            </div>

                            <div class="grow">
                                <label class="font-bold block">Client_ID</label>
                                <InputText v-model="configs.client_id_production" class="w-full p-2 border" placeholder="Insira o Cliente ID" />
                            </div>

                            <div class="grow">
                                <label class="font-bold block">Client_Secret</label>
                                <InputText v-model="configs.client_secret_production" class="w-full p-2 border" placeholder="Insira o Cliente Secret" />
                            </div>

                            <div class="grow">
                                <label class="font-bold block">Certificado</label>
                                <Textarea v-model="configs.certificate_production" class="w-full p-2 border" rows="15" placeholder="Insira o Certificado" />
                            </div>
                        </div>
                    </Fieldset>

                </div>

                <Button
                    icon="pi pi-save"
                    label="Salvar Configurações"
                    class="p-2 px-4 mt-10 bg-green-500 text-white fixed right-5 bottom-5 z-50"
                    @click="saveConfigs"
                />

                <Toast/>

                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarDashboard from '@/components/Dashboard/SidebarDashboard.vue'
import Header from '@/components/Dashboard/HeaderDashboard.vue'

import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Fieldset from 'primevue/fieldset'
import Chips from 'primevue/chips'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import InputGroup from 'primevue/inputgroup'
import InputGroupAddon from 'primevue/inputgroupaddon'

import configurationsService from '@/services/configurationsService'
import requestService from '@/services/requestService'

export default {
    data() {
        return {
            currentTitle: "",
            plans: null,
            plans_fields: 1,
            configs: {
                vehicle_types: null,
                vehicle_brands: null,
                pix_key: null,
                client_id_production: null,
                client_secret_production: null
            }
        }
    },
    methods: {
        addNewPlan() {
            this.plans.push({
                plan_id: this.plans[this.plans.length - 1].plan_id + 1,
                plan_name: null,
                plan_price: null,
                plan_months_period: null,
                plan_days_period: null,
                plan_status: 1
            })
            this.plans_fields++
        },
        changeStatusPlan(plan) {
            plan.plan_status = !plan.plan_status

            requestService.post('/configurations/change-status-plan', {
                plan_id: plan.plan_id
            }).then((response) => {
                if (response.data.status !== 200) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro ao excluir plano!',
                        life: 3000
                    });
                    console.log(response)
                    return false
                }

                if (!plan.plan_status) {
                    this.$toast.add({
                        severity: 'warn',
                        summary: `Plano ${plan.plan_name} Desativado!`,
                        life: 3000
                    });
                } else {
                    this.$toast.add({
                        severity: 'success',
                        summary: `Plano ${plan.plan_name} Ativado!`,
                        life: 3000
                    });
                }
            })
        },
        formatPrice(price) {
            return price.toLocaleString('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2, maximumFractionDigits: 2
            })
        },
        saveConfigs() {
            configurationsService.updateConfigs({
                plans: this.plans,
                configs: this.configs
            }).then((response) => {
                console.log(response)
                if (response.data && response.data.status === 200) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Configurações Atualizadas!',
                        detail: 'As configurações do sistemas foram atualizadas com sucesso.',
                        life: 3000
                    });
                }
            })
        }
    },
    beforeMount() {
        configurationsService.getAllConfigurations().then((response) => {
            response.data.map((index) => {
                switch (index.configuration_key) {
                    case 'payment_grace_period':
                        this.configs.payment_grace_period = index.configuration_value
                        break
                    case 'vehicle_brands':
                        this.configs.vehicle_brands = JSON.parse(index.configuration_value)
                        break
                    case 'vehicle_types':
                        this.configs.vehicle_types = JSON.parse(index.configuration_value)
                        break
                    case 'pix_key':
                        this.configs.pix_key = index.configuration_value
                        break
                    case 'client_id_production':
                        this.configs.client_id_production = index.configuration_value
                        break
                    case 'client_secret_production':
                        this.configs.client_secret_production = index.configuration_value
                        break
                    case 'certificate_production':
                        this.configs.certificate_production = index.configuration_value
                        break
                    case 'plans':
                        this.plans = JSON.parse(index.configuration_value)
                        break
                }
            })

            this.plans.map((index, key) => {
                this.plans[key].plan_price = this.formatPrice(index.plan_price).replace(/,/g, '')
            })
        })
    },
    mounted() {
        this.currentTitle = this.$route.meta.title
        this.$watch("$route", () => {
            this.currentTitle = this.$route.meta.title;
        });
    },
    components: {
        SidebarDashboard, Header, InputText, InputNumber, InputGroup, InputGroupAddon, Textarea, Fieldset, Chips, Button
    }
}
</script>

<style lang="scss">
.chips {
    ul {
        width: 100% !important;
        margin: 0 !important;
        padding: 8px !important;
    }
    li {
        padding: 5px 10px !important;
        margin: 0 8px 5px 0;
        transition: all 0.2s;
        cursor: pointer;
    }
    li:not(.p-chips-input-token):hover {
        background: #2563eb;
        color: #ffffff !important;
    }
    li span {
        font-weight: 600;
    }
    input {
        padding: 8px 0;
    }
}

</style>