import { defineStore } from 'pinia'

export const authStore = defineStore({
  'id': 'auth',
  state: () => ({
    token: localStorage.getItem('token'),
    userData: JSON.parse(localStorage.getItem('userData')),
    customerData: JSON.parse(localStorage.getItem('customerData')),
    expireToken: localStorage.getItem('expireToken'),
  }),
  actions: {
    setToken(tokenValue) {
      localStorage.setItem('token', tokenValue)
    },
    setExpireToken(expire) {
      localStorage.setItem('expireToken', expire)
    },
    setUserData(userDataValue) {
      localStorage.setItem('userData', JSON.stringify(userDataValue))
    },
    setCustomerData(customerDataValue) {
      localStorage.setItem('customerData', JSON.stringify(customerDataValue))
    },
    removeToken() {
      localStorage.removeItem('token')
      this.token = null
    },
    removeUserData() {
      localStorage.removeItem('userData')
      this.userData = null
    },
    removeExpireToken() {
      localStorage.removeItem('expireToken')
      this.expireToken = null  
    },
    removeCustomers() {
      localStorage.removeItem('customers')
    }
  },
  getters: {
    getToken() {
      return this.token
    },
    getUserData() {
      return this.userData
    },
    getCustomerData() {
      return JSON.parse(localStorage.getItem('customerData'))
    },
    getExpireToken() {
      return this.expireToken
    }
  }
});