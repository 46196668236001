import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import { authStore } from '@/store/authStore'
import authService from '@/services/authService'

// Views
import Dashboard from '@/views/DashboardView.vue'
import DashboardLogin from '@/views/DashboardLoginView.vue'
import CustomerLoginViewVue from '@/views/CustomerLoginView.vue'
import CustomerDashboard from '@/views/CustomerDashboard.vue'
import CustomerOrder from '@/views/CustomerOrderView.vue'
import CustomerContracts from '@/views/CustomerContractsView.vue'
import CustomerConfigurations from '@/views/CustomerConfigurations.vue'
import PaymentOrderViewVue from '@/views/PaymentOrderView.vue'
import DashboardCustomersVue from '@/views/DashboardCustomers.vue'
import DashboardVehiclesVue from '@/views/DashboardVehicles.vue'
import DashboardOrdersVue from '@/views/DashboardOrders.vue'
import DashboardReportingsVue from '@/views/DashboardReportings.vue'
import DashboardSettingsVue from '@/views/DashboardSettings.vue'
import DashboardEditProfile from '@/views/DashboardEditProfile.vue'
import DashboardContractModelRenderVue from '@/views/DashboardContractModelRender.vue'
import DashboardContractModelVue from '@/views/DashboardContractModel.vue'
import DashboardNewContractModelVue from '@/views/DashboardNewContractModel.vue'
import DashboardEditContractModelVue from '@/views/DashboardEditContractModel.vue'

const routes = [
  {
    path: '/',
    name: 'Login Cliente',
    component: CustomerLoginViewVue,
    meta: {
      title: 'Login - Cliente',
      requireAuthCustomer: true,
    },
  },
  {
    path: '/area-cliente',
    name: 'Painel do Cliente',
    component: CustomerDashboard,
    meta: {
      title: 'Painel - Cliente',
      requireAuthCustomer: true,
    },
  },
  {
    path: '/area-cliente/meus-contratos',
    name: 'Contratos',
    component: CustomerContracts,
    meta: {
      title: 'Meus Contratos',
      requireAuthCustomer: true
    }
  },
  {
    path: '/area-cliente/configuracoes',
    name: 'configuracoes',
    component: CustomerConfigurations,
    meta: {
      title: 'Configurações',
      requireAuthCustomer: true
    }
  },
  {
    path: '/area-cliente/confirmacao',
    name: 'Pedido',
    component: CustomerOrder,
    meta: {
      title: 'Painel - Pedido',
      requireAuthCustomer: true,
    },
  },
  {
    path: '/area-cliente/pedido/:hash/pagamento',
    name: 'Pagamento Pedido',
    component: PaymentOrderViewVue,
    meta: {
      title: 'Pagamento',
      requireAuthCustomer: true,
      hideBack: true
    }
  },

  // Dashboard
  {
    path: '/painel/login',
    name: 'Dashboard Login',
    component: DashboardLogin,
    meta: {
      title: 'Online Tecnologia - Dashboard Login',
    },
  },
  {
    path: '/painel',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      requireAuth: true,
    }
  },
  {
    path: '/painel/editar-perfil',
    name: 'Editar Perfil',
    component: DashboardEditProfile,
    meta: {
      title: 'Editar Perfil',
      requireAuth: true,
    }
  },
  {
    path: '/painel/clientes',
    name: 'Clientes',
    component: DashboardCustomersVue,
    meta: {
      title: 'Clientes',
      requireAuth: true,
    }
  },
  {
    path: '/painel/veiculos',
    name: 'Dashboard Veículos',
    component: DashboardVehiclesVue,
    meta: {
      title: 'Veículos',
      requireAuth: true,
    }
  },
  {
    path: '/painel/pedidos',
    name: 'Dashboard Pedidos',
    component: DashboardOrdersVue,
    meta: {
      title: 'Pedidos',
      requireAuth: true,
    }
  },
  {
    path: '/painel/modelos-contratos',
    component: DashboardContractModelRenderVue,
    children: [
      {
        path: "",
        name: 'Dashboard Modelos Contratos',
        component: DashboardContractModelVue,
        meta: {
          title: 'Modelos de Contratos',
          requireAuth: true,
        },
      },
      {
        path: "new",
        name: 'new-contract-model',
        component: DashboardNewContractModelVue,
        meta: {
          title: 'Novo Modelo de Contrato',
          requireAuth: true
        }
      },
      {
        path: "edit/:model_id",
        name: 'edit-contract-model',
        component: DashboardEditContractModelVue,
        meta: {
          title: 'Editar Modelo de Contrato',
          requireAuth: true
        }
      }
    ]
  },
  {
    path: '/painel/relatorios',
    name: 'Dashboard Relatórios',
    component: DashboardReportingsVue,
    meta: {
      title: 'Relatórios',
      requireAuth: true,
    }
  },
  {
    path: '/painel/configuracoes',
    name: 'Dashboard Configurações',
    component: DashboardSettingsVue,
    meta: {
      title: 'Configurações',
      requireAuth: true,
    }
  },
]

// Validation Dashboard Authentication
const isAuthenticated = async () => {
  try {
    const token = 'Bearer '+localStorage.getItem('token')
    return await axios.get('/validate-jwt', {
      headers: {
        Authorization: token
      }
    }).then((response) => {
      console.log(response)
    })
  } catch(e) {
    if (e.response.status) {
      localStorage.removeItem('userData')
      localStorage.removeItem('token')
      localStorage.removeItem('expireToken')
      //document.location.reload()
      router.push('/')
    }
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Online Tecnologia'
  next()
})

// Validation Dashboard Login
// router.beforeEach(async (to, from, next) => {
//   const store = authStore()

//   if (to.meta?.requireAuth) {
//     if (store.getToken && store.getUserData && isAuthenticated()) {
//       next('/painel')
//     } else {
//       next('/painel/login')
//     }
//     return;
//   }

//   next()
// });

router.beforeEach(async (to, from, next) => {
  const auth = authStore()

  if (to.meta?.requireAuth) {
    if (auth.getToken && auth.getUserData) {
      //const isAuthenticated = await auth.checkToken()

      if (isAuthenticated) {
        next()
      } else {
        next('/painel/login')
      }
    } else {
      next('/painel/login')
    }
  } else {
    next()
    return
  }
});

export default router