<template>
    <div class="block-customer-dashboard bg-white rounded-xl shadow-md px-6 py-5">
        <RouterLink :to="link" class="text-center flex flex-col items-center cursor-pointer">
            <img class="mb-5 w-16 h-16" :src="icon" alt="">
            <h2 class="text-xs font-bold">{{ title }}</h2>
        </RouterLink>
    </div>
</template>

<script>
import { RouterLink } from 'vue-router';

    export default {
        name: 'Boxes Customer Component',
        props: {
            title: '',
            icon: '',
            link: ''
        }
    }
</script>

<style lang="scss" scoped>
.block-customer-dashboard {
    width: 100%;
    max-width: 220px;
}
</style>