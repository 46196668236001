import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'

import PrimeVue from 'primevue/config'
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import Dialog from 'primevue/dialog';
import DialogService from 'primevue/dialogservice'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'

import 'primevue/resources/themes/lara-light-teal/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import './registerServiceWorker'
import 'typeface-poppins'
import 'sweetalert2/dist/sweetalert2.min.css'

import './app.css'

const app = createApp(App)
const pinia = createPinia()

app.use(PrimeVue, {
    ripple: true,
    inputStyle: "filled",
})
app.use(ConfirmationService);
app.use(ToastService);
app.use(VueSweetalert2)
app.use(Toast)

app.component('Toast', Toast)
app.component('ConfirmDialog', ConfirmDialog)

app.use(router)
app.use(pinia)
axios.defaults.baseURL = 'https://api.onlinedobrasil.com/api'
app.config.globalProperties.$axios = axios
app.mount('#app')
