<template>
    <footer class="bg-slate-200 app-footer">
        <div class="copyright py-4 px-10 text-center text-blue-800 lato text-xs">
            <p>Online Tecnologia &copy; {{ this.getYear }}. Todos os Direitos Reservados.</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'footer-component',
    computed: {
        getYear() {
            const year = new Date()
            return year.getFullYear()
        }
    }
}
</script>

<style scoped lang="scss">
</style>